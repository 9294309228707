import { LocationType } from '@shared/enums';
import { LocationLightModel } from '@shared/models';

export abstract class BaseLocationName {
	protected _locationsSourceDictionary: Record<string, LocationLightModel> = {};
	protected _locationsSource: LocationLightModel[] = [];

	public findNameByKey(locationKey: string): string {
		if (this._locationsSourceDictionary[locationKey]) {
			return this._locationsSourceDictionary[locationKey].name;
		}
		return 'N/A';
	}

	public findLocationByKey(locationKey: string): LocationLightModel | undefined {
		if (this._locationsSourceDictionary[locationKey]) {
			return this._locationsSourceDictionary[locationKey];
		}
		return undefined;
	}

	public findLocationByName(name: string | undefined, typeFilter?: LocationType[]): LocationLightModel | undefined {
		let locations = this._locationsSource;
		if (typeFilter) {
			locations = this._locationsSource.filter(loc => typeFilter.some(_ => loc.type == _));
		}
		return locations.find(_ => _.name.toLowerCase() == name?.toLocaleLowerCase());
	}
}
