import {
	CustomerPermissionViewModel,
	LocationPermissionViewModel,
	LocationTypePermissionViewModel,
} from '@core/models';
import { LocationLightModel } from '@shared/models';

export class PermissionsDictionaryHelper {
	static getVendorPermissionDictionary(
		vendors: CustomerPermissionViewModel[],
	): Record<number, CustomerPermissionViewModel> {
		return vendors.reduce<Record<number, CustomerPermissionViewModel>>((acc, cur) => {
			acc[cur.id] = cur;
			return acc;
		}, {});
	}

	static getLocationTypePermissionDictionary(
		locationTypes: LocationTypePermissionViewModel[],
	): Record<string, LocationTypePermissionViewModel> {
		return locationTypes.reduce<Record<string, LocationTypePermissionViewModel>>((acc, cur) => {
			acc[cur.locationType] = cur;
			return acc;
		}, {});
	}

	static getLocationPermissionDictionary(
		locations: LocationPermissionViewModel[],
	): Record<number, LocationPermissionViewModel> {
		return locations.reduce<Record<number, LocationPermissionViewModel>>((acc, cur) => {
			acc[cur.id] = cur;
			return acc;
		}, {});
	}

	static getTypedLocationDictionary(locations: LocationLightModel[]): Record<string, LocationLightModel[]> {
		return locations.reduce<Record<string, LocationLightModel[]>>((acc, cur) => {
			if (!acc.hasOwnProperty(cur.type)) {
				acc[cur.type] = [];
			}
			acc[cur.type].push(cur);
			return acc;
		}, {});
	}

	//TODO: remove this method
	static getLocationDictionary(locations: LocationLightModel[]): Record<number, LocationLightModel[]> {
		return locations.reduce<Record<number, LocationLightModel[]>>((acc, cur) => {
			if (!acc.hasOwnProperty(cur.vendorId)) {
				acc[cur.vendorId] = [];
			}
			acc[cur.vendorId].push(cur);
			return acc;
		}, {});
	}
}
