import { Injectable } from '@angular/core';
import { OrderDirection, Role } from '@shared/enums';
import { LocationLightModel } from '@shared/models';
import { CustomerNameService } from '@shared/services';

import { IUserModel, RoleViewModel } from '@admin/users/models';
import { PermissionsDictionaryHelper } from '@admin/users/services/dictionary-helper';
import { LocationService } from '@core/services';

import { RecordHelper } from '@shared/util';
import { Observable, map, of } from 'rxjs';
import {
	CustomerPermissions,
	CustomerPermissionViewModel,
	ILocationRequest,
	LocationOrderField,
	LocationPermissions,
	LocationPermissionViewModel,
	LocationTypePermissionViewModel,
} from '@core/models';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	constructor(
		private _locationService: LocationService,
		private _vendorNameService: CustomerNameService,
	) {}

	public get(vendorId: number): Observable<CustomerPermissionViewModel> {
		const request: ILocationRequest = {
			customerIds: [vendorId],
			orderBy: LocationOrderField.CustomerId,
			orderDirection: OrderDirection.Ascending,
		};
		return this._locationService.getAll(request).pipe(
			map(locations => {
				return {
					isChanged: false,
					isChecked: false,
					id: vendorId,
					name: this._vendorNameService.findNameById(vendorId),
					locationTypes: this.getLocationTypeViewModel(locations, null),
				};
			}),
		);
	}

	public getViewModel(permissions: CustomerPermissions): Observable<CustomerPermissionViewModel[]> {
		if (!permissions) {
			return of([]);
		}
		const vendorIds: number[] = RecordHelper.getKeys(permissions);
		const request: ILocationRequest = {
			customerIds: vendorIds,
			orderBy: LocationOrderField.CustomerId,
			orderDirection: OrderDirection.Ascending,
		};
		return this._locationService.getAll(request).pipe(
			map(locations => {
				const locationsByVendor = PermissionsDictionaryHelper.getLocationDictionary(locations);
				const permissionViewModels: CustomerPermissionViewModel[] = [];

				for (const vendorId in permissions) {
					const locationPermission: LocationPermissions | null = permissions[vendorId];

					const permissionMOdel: CustomerPermissionViewModel = {
						id: +vendorId,
						isChanged: true,
						isChecked: locationPermission == null,
						name: this._vendorNameService.findNameById(+vendorId),
						locationTypes: this.getLocationTypeViewModel(locationsByVendor[vendorId], locationPermission),
					};

					permissionViewModels.push(permissionMOdel);
				}
				return permissionViewModels;
			}),
		);
	}

	public getRoles(user: IUserModel): RoleViewModel[] {
		const roles: RoleViewModel[] = [];
		if (user.isAdmin) {
			roles.push({
				role: Role.Administrator,
				vendorNames: null,
			});
			return roles;
		}

		let vendorAdminRoleVendorIds = new Set<number>();
		let userRoleVendorIds = new Set<number>();

		for (const vendorId in user.permissions) {
			const value = user.permissions[vendorId];
			if (!value) {
				vendorAdminRoleVendorIds.add(+vendorId);
			} else {
				userRoleVendorIds.add(+vendorId);
			}
		}

		if (vendorAdminRoleVendorIds.size > 0) {
			const vendorNames = this.getVendorNames([...vendorAdminRoleVendorIds]);
			if (vendorNames) {
				roles.push({
					role: Role.Manager,
					vendorNames: vendorNames,
				});
			}
		}

		if (userRoleVendorIds.size > 0) {
			const vendorNames = this.getVendorNames([...userRoleVendorIds]);
			if (vendorNames) {
				roles.push({
					role: Role.User,
					vendorNames: vendorNames,
				});
			}
		}
		return roles;
	}

	public getPermissionsDictionary(model: CustomerPermissionViewModel[]): CustomerPermissions {
		const vendorDictionary: CustomerPermissions = model.reduce<CustomerPermissions>(
			(permissions, vendorPermission) => {
				if (vendorPermission.isChecked) {
					permissions[vendorPermission.id] = null;
					return permissions;
				}

				if (vendorPermission.locationTypes) {
					const locationTypePermissions = this.getLocationTypePermission(vendorPermission.locationTypes);

					if (Object.keys(locationTypePermissions).length > 0) {
						permissions[vendorPermission.id] = locationTypePermissions;
					}
				}
				return permissions;
			},
			{},
		);
		return vendorDictionary;
	}

	private getVendorNames(vendorIds: number[]) {
		return vendorIds
			.filter(e => this._vendorNameService.isKnow(e))
			.map(e => this._vendorNameService.findNameById(e))
			.join(', ');
	}

	private getLocationTypePermission(locationTypes: LocationTypePermissionViewModel[]): LocationPermissions {
		return locationTypes.reduce<LocationPermissions>((acc, locationTypePermission) => {
			if (locationTypePermission.isChecked) {
				acc[locationTypePermission.locationType] = null;
			} else {
				const locations = locationTypePermission.locations.filter(_ => _.isChecked).map(_ => _.id);
				if (locations.length > 0) {
					acc[locationTypePermission.locationType] = locations;
				}
			}
			return acc;
		}, {});
	}

	private getLocationTypeViewModel(
		locations: LocationLightModel[],
		locationPermissions: LocationPermissions | null,
	): LocationTypePermissionViewModel[] | null {
		if (!locations) {
			return null;
		}
		const types = [...new Set(locations.map(item => item.type).sort())];
		const typedLocationsDictionary: Record<string, LocationLightModel[]> =
			PermissionsDictionaryHelper.getTypedLocationDictionary(locations);

		return types.map<LocationTypePermissionViewModel>(type => {
			var isChanged = false;
			var isChecked = false;
			var locationIds: number[] | null = null;
			if (locationPermissions && locationPermissions.hasOwnProperty(type)) {
				locationIds = locationPermissions[type];
				isChecked = locationIds == null;
				isChanged = true;
			}
			return {
				isChanged: isChanged,
				isChecked: isChecked,
				locationType: type,
				locations: this.getLocationViewModels(typedLocationsDictionary[type], locationIds).sort(
					(a, b) => +b.isChecked - +a.isChecked,
				),
			};
		});
	}

	private getLocationViewModels(
		locations: LocationLightModel[],
		locationIds: number[] | null,
	): LocationPermissionViewModel[] {
		return locations.map<LocationPermissionViewModel>(location => {
			var isChecked = false;
			if (locationIds) {
				isChecked = locationIds.some(_ => _ == location.id);
			}
			return {
				isChecked: isChecked,
				name: location.name,
				id: location.id,
			};
		});
	}
}
