import { Injectable } from '@angular/core';
import { ILocationRequest, LocationOrderField } from '@core/models';
import { LocationService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderDirection } from '@shared/enums';
import { CustomerLightModel, LocationLightModel } from '@shared/models';
import { IVendorModel } from '@shared/models/vendor';
import { UserContextService, CustomerService } from '@shared/services';
import { BehaviorSubject, Observable, shareReplay, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
//TODO: optimize this service
//we need to decide whether we will download all locations or locations will be downloaded by vendorId
@UntilDestroy()
export class MetaDataService {
	private _locations$: BehaviorSubject<LocationLightModel[]>;
	private _selectedVendor$: Observable<IVendorModel>;

	constructor(
		private _customerService: CustomerService,
		private _userContextService: UserContextService,
		private _locationService: LocationService,
	) {
		this._locations$ = new BehaviorSubject<LocationLightModel[]>([]);
		this._selectedVendor$ = this._userContextService.getVendor();
	}

	public init(): void {
		this.privateGetLocations();
	}

	public getCustomers(): Observable<CustomerLightModel[]> {
		return this._customerService.getVendors();
	}

	public getLocations(): Observable<LocationLightModel[]> {
		return this._locations$;
	}

	private privateGetLocations(): void {
		this._selectedVendor$
			.pipe(
				switchMap(_ => {
					const request: ILocationRequest = {
						customerIds: [_.id],
						orderBy: LocationOrderField.CustomerId,
						orderDirection: OrderDirection.Ascending,
					};
					return this._locationService.getAll(request);
				}),
				untilDestroyed(this),
				shareReplay(),
			)
			.subscribe(this._locations$);
	}
}
