export enum LocationOrderField {
	Id = 'Id',
	Name = 'Name',
	Country = 'Country',
	State = 'State',
	City = 'City',
	Zip = 'Zip',
	Contact = 'Contact',
	Type = 'Type',
	CustomerId = 'VendorId',
}
