<div class="left-side-menu">
	<div class="customer-name-wrapper">
		<div class="customer-name notranslate">
			{{ (this.vendorService.getVendor() | async)?.name }}
		</div>
	</div>

	<nav class="sidebar-wrapper" *ngIf="role$ | async as role">
		<ul *ngIf="menuItems$ | async as menuItems">
			<li *ngFor="let menuItem of menuItems" class="side-nav-item">
				<a
					*ngIf="!menuItem.href; else navigateByHref"
					[routerLink]="menuItem.routeLink"
					routerLinkActive="active"
					class="side-nav-link"
				>
					<i class="mdi" [ngClass]="menuItem.iconClass"></i>
					<span>{{ menuItem.title }} </span>
					<span *ngIf="menuItem.badge" class="menu-item-badge-filled">{{ menuItem.badge }}</span>
				</a>
				<ng-template #navigateByHref>
					<a [href]="menuItem.href" class="side-nav-link">
						<i class="mdi" [ngClass]="menuItem.iconClass"></i>
						<span>{{ menuItem.title }} </span>
					</a>
				</ng-template>
			</li>
		</ul>
	</nav>

	<span class="app-version">
		v{{ appVersion }}
		<span style="display: none">
			{{ appInformationalVersion }}
		</span>
	</span>
</div>
