import { Role } from '@shared/enums';

export class TokenHelper {
	public static hasAdminRole(userData: any): boolean {
		return userData.role == 'admin';

	}

	public static getMangerVendorIds(userData: any) {
		let ids: number[] = [];

		if (userData.VendorId) {
			if (Array.isArray(userData.VendorId)) {
				ids = this.toNumberArray(userData.VendorId);
			} else {
				ids = this.toNumberArray([userData.VendorId]);
			}
		}
		return ids;
	}

	private static toNumberArray(ids: string[]) {
		return ids.map(_ => +_);
	}

	public static getRole(userData: any, selectedVendorId: number): Role {
		if (this.hasAdminRole(userData)) {
			return Role.Administrator;
		}

		const vendorIds = this.getMangerVendorIds(userData);
		if (vendorIds.some(_ => _ == selectedVendorId)) {
			return Role.Manager;
		}

		return Role.User;
	}

	public static getUserId(usedData: any): string {
		return usedData.sub;
	}

	public static getAvailableVendorIds(userData: any): number[] {
		const vendorIds = this.getMangerVendorIds(userData);

		const locationKeys = userData.LocationKey;

		let ids = new Set<string>();

		if (locationKeys) {
			if (Array.isArray(locationKeys)) {
				locationKeys.forEach(key => {
					ids.add(key.split('|')[0]);
				});
			} else {
				ids.add(locationKeys.split('|')[0]);
			}
		}

		return vendorIds.concat(this.toNumberArray([...ids]));
	}
}
