export class CompareUtils {
	public static isEqual<T>(firstObject: T, secondObject: T): boolean {
		const firstObjectStringify: string = this.objectToString(firstObject);
		const secondObjectStringify: string = this.objectToString(secondObject);

		return firstObjectStringify === secondObjectStringify;
	}

	private static objectToString(obj: any): string {
		return JSON.stringify(obj);
	}
}
