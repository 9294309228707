<div
	class="input-component-wrapper {{ containerClass }}"
	[ngClass]="{ 'has-error-message': error, required: required }"
	[ngStyle]="{
		maxWidth: maxWidth + 'px',
		minWidth: minWidth + 'px',
	}"
>
	<div class="control-input-container">
		<label [for]="id">{{ label }}<span class="required-marker"></span></label>
		@if (readonly) {
			<div class="input-box readonly notranslate">
				<span class="readonly-input">{{ value }}</span>
			</div>
		} @else {
			<div class="input-box notranslate">
				<input
					gaEvent="Changed {{ label }} input"
					gaBind="change"
					class="control-input"
					[type]="type"
					[name]="label"
					[minValue]="minNumValue"
					[id]="id"
					[(ngModel)]="value"
					[placeholder]="placeholder"
					(change)="valueChanged()"
				/>
			</div>
		}
	</div>

	@if (error) {
		<div [ngClass]="errorPosition">
			<p-message severity="danger" summary="Error" [text]="error"></p-message>
		</div>
	}
</div>
