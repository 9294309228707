import { Injectable } from '@angular/core';
import { DateHelper } from '@core/utils/date.helper';
import { ProductViewModel } from '@shared/models/products';

@Injectable({
	providedIn: 'root',
})
export class ProductValidatorService {
	private readonly length50message: string = 'Length must be less than 50';
	private readonly possitive: string = 'must be a positive number';
	//length no more than 9999999.99
	private costLengthPattern: RegExp = new RegExp(
		'^\\$?((\\d{1,3})(?:,[0-9]{3}){0,1}|(\\d{1})(?:,[0-9]{3}){0,2}|(\\d{1,7}))(\\.\\d{1,2})?$',
	);
	private readonly worngDate: string = 'has wrong date format.';
	public readonly unkonwn = 'Unknown';

	constructor() {}

	public validate(product: ProductViewModel) {
		this.clearValidationErrors(product);

		if (product.productId == 0 && product.sku != this.unkonwn) {
			product.errors.sku = `Product with sku (${product.sku}) not found.`;
		}

		if (product.sku == this.unkonwn) {
			product.errors.sku = 'Sku is missing';
		}

		if (product.sku.length > 100) {
			product.errors.sku = 'Length must be less than 100';
		}

		if (product.serialNumber && product.serialNumber?.length > 50) {
			product.errors.serialNumber = this.length50message;
		}

		if (product.lotNumber && product.lotNumber?.length > 50) {
			product.errors.lotNumber = this.length50message;
		}

		if (isNaN(product.count)) {
			product.count = 0;
			product.errors.count = `Count ${this.possitive}`;
		} else if (product.count <= 0) {
			product.errors.count = `Count ${this.possitive}`;
		} else if (product.count > 10000) {
			product.errors.count = `Count must be less than 10000`;
		}

		if (product.cost && product.cost < 0) {
			product.errors.cost = `Cost ${this.possitive}`;
		} else if (product.cost && !this.costLengthPattern.test(product.cost.toString())) {
			product.errors.cost = 'Cost must be less than 9999999.99';
		}

		if (product.bornDate && !DateHelper.isValid(product.bornDate)) {
			product.errors.bornDate = `Born Date ${this.worngDate}`;
		}

		if (product.sellBy && !DateHelper.isValid(product.sellBy)) {
			product.errors.sellBy = `Sell By ${this.worngDate}`;
		}

		if (product.expirationDate && !DateHelper.isValid(product.expirationDate)) {
			product.errors.expirationDate = `Expiration Date ${this.worngDate}`;
		}

		product.hasErrors = Object.keys(product.errors).length > 0;
	}

	private clearValidationErrors(product: ProductViewModel) {
		product.hasErrors = false;
		product.errors = {};
	}
}
