import { Directive, HostListener, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Directive({
	selector: '[gaModelChangeEvent]',
	standalone: true,
})
export class GaModelChangeEventDirective {
	@Input() gaModelChangeEvent!: string;

	constructor(private gaService: GoogleAnalyticsService) {}

	@HostListener('ngModelChange', ['$event']) onChange(): void {
		this.gaService.event(this.gaModelChangeEvent);
	}
}
