import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ICreateOrderPrintJobModel } from '@printing/models';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OrderPrintJobService {
	private portalUrl: string;

	constructor(
		private _http: HttpClient,
	) {
		this.portalUrl = environment.portalBaseAddress;
	}

	public create(request: ICreateOrderPrintJobModel): Observable<Object> {
		return this._http.post(`${this.portalUrl}/api/v1/printing/orders`, request);
	}
}
