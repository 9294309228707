import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { IPrintJobViewModel } from '@printing/models';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PrintJobNotificationService {
	private hubConnection: signalR.HubConnection;

	private readonly changedEventName = 'Changed';

	constructor(private oidcSecurityService: OidcSecurityService) {
		const options: signalR.IHttpConnectionOptions = {
			accessTokenFactory: () => firstValueFrom(this.oidcSecurityService.getAccessToken()),
		};

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(environment.portalBaseAddress + '/signal/v1/print-job', options)
			.configureLogging(environment.sirnalRLogLevel)
			.build();
	}

	public startConnection = () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err));
	};

	public addChangedListener = (callback: (model: IPrintJobViewModel) => void) => {
		this.hubConnection.on(this.changedEventName, (model: IPrintJobViewModel) => {
			callback(model);
		});
	};

	public removeChangedListener = () => {
		this.hubConnection.off(this.changedEventName);
	};
}
