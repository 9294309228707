import { Injectable } from '@angular/core';
import { OffsetPagination } from '@shared/models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export abstract class BasePagination {
	public pager$: BehaviorSubject<OffsetPagination> = new BehaviorSubject<OffsetPagination>(
		new OffsetPagination(0, 10),
	);
	public totalCount: number = 0;

	constructor(pager?: OffsetPagination) {
		if (pager) {
			this.pager$.next(pager);
		}
	}

	public resetPager() {
		this.pager$.next(new OffsetPagination(0, this.pager$.value.take));
	}
}
