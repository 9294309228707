import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { IExcel } from '@core/models';
import * as ExcelJS from 'exceljs';
import { FileSelectEvent } from 'primeng/fileupload';
import { from, fromEvent, switchMap } from 'rxjs';

@Directive({
	selector: '[excelReader]',
})
export class ExcelReaderDirective {
	@Output() dataChanged = new EventEmitter<IExcel>();
	private _reader: FileReader;
	private _fileName!: string;

	constructor(private el: ElementRef<HTMLInputElement>) {
		this._reader = new FileReader();

		fromEvent(this._reader, 'loadend')
			.pipe(
				switchMap(_ => {
					const reader = _.target as FileReader;
					const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
					return from(workbook.xlsx.load(reader.result as ArrayBuffer));
				}),
			)
			.subscribe(_ => {
				el.nativeElement.value = '';
				this.dataChanged.emit({ workbook: _, fileName: this._fileName });
			});
	}

	@HostListener('onSelect', ['$event']) onFileSelected(event: FileSelectEvent) {
		if (event.currentFiles.length > 0) {
			this._fileName = event.currentFiles[0].name;
			this._reader.readAsArrayBuffer(event.currentFiles[0]);
		}
	}
}
