export class RecordHelper {
	public static getKeys<K extends PropertyKey, T>(object: Record<K, T>) {
		return Object.keys(object) as K[];
	}

	public static getEntries<K extends PropertyKey, T>(object: Record<K, T>) {
		return Object.entries(object) as [K, T][];
	}

	public static toRecord<T extends Record<string, any>, K extends keyof T>(array: T[], selector: K): Record<T[K], T> {
		return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item }), {} as Record<T[K], T>);
	}
}
