<p-toast
	styleClass="confirm-message"
	[position]="position"
	[key]="key"
	showTransformOptions="translateX(100%)"
	hideTransformOptions="translateX(100%)"
	(onClose)="decline()"
	[life]="10000"
	[baseZIndex]="5000"
>
	<ng-template let-message pTemplate="message">
		<div class="toast-content-container">
			<div class="text-content">
				<i class="mdi mdi-alert-outline"></i>
				@if (message.summary) {
					<p>{{ message.summary }}</p>
				}
				<h4>{{ message.detail }}</h4>
			</div>
			<div class="toast-buttons">
				<app-button [variant]="buttonVariantEnum.Primary" [text]="confirmButtonText" (click)="confirm()" />
				<app-button [variant]="buttonVariantEnum.Light" text="No" (click)="decline()" />
			</div>
		</div>
	</ng-template>
</p-toast>
