export enum OrderType {
	Purchase = 'Purchase',
	Sales = 'Sales',
	Transfer = 'Transfer',
}

export enum DisplayOrderType {
	All = 'all',
	Purchase = 'purchase',
	Sales = 'sales',
	Transfer = 'transfer',
}

export const orderTypeValues: Record<DisplayOrderType, OrderType | undefined> = {
	[DisplayOrderType.All]: undefined,
	[DisplayOrderType.Purchase]: OrderType.Purchase,
	[DisplayOrderType.Sales]: OrderType.Sales,
	[DisplayOrderType.Transfer]: OrderType.Transfer,
};
