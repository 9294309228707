import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	private readonly companyName: string = 'Simple RFID';
	private readonly titleKey: string = 'title';

	constructor(
		private title: Title,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}

	public initialize(): void {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const newTitle = this.generateTitle();
				this.setTitle(newTitle);
			}
		});
	}

	private setTitle(newTitle: string): void {
		this.title.setTitle(newTitle);
	}

	private generateTitle(): string {
		let route = this.activatedRoute;
		let title: string = this.companyName;
		while (route.firstChild) {
			route = route.firstChild;
		}
		if (route.snapshot.data[this.titleKey]) {
			title = `${route.snapshot.data[this.titleKey]} | ${this.companyName}`;
		}
		return title;
	}
}
