import { Component, OnDestroy, OnInit } from '@angular/core';
import { Role } from '@shared/enums';
import { NavigationService, UserContextService, UserRoleService } from '@shared/services';
import packageInfo from 'package.json';
import { combineLatest, map, Observable } from 'rxjs';
import { IVendorModel } from '@shared/models/vendor';
import { environment } from '@environments/environment';
import { DisplayOrderType } from '@orders/enums';

@Component({
	selector: 'app-nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
	public menuItems$: Observable<IMenuItem[]>;

	public appVersion: string;
	public appInformationalVersion: string;

	public role$: Observable<Role>;
	public vendor$: Observable<IVendorModel>;

	constructor(
		public vendorService: UserContextService,
		private navigationService: NavigationService,
		private userRoleService: UserRoleService,
	) {
		this.appVersion = packageInfo.version;
		this.appInformationalVersion = packageInfo.description;
		this.role$ = this.userRoleService.getRole();
		this.vendor$ = this.vendorService.getVendor();

		this.menuItems$ = combineLatest({
			vendor: this.vendor$,
			role: this.role$,
		}).pipe(
			map(resp => {
				const vendor = resp.vendor;
				let result: IMenuItem[] = [];
				switch (resp.role) {
					case Role.Administrator: {
						result = this.addAdminMenuItems(vendor);
						break;
					}
					case Role.Manager: {
						result = this.addManagerMenuItems(vendor);
						break;
					}
					case Role.User: {
						result = this.addUserMenuItems(vendor);
						break;
					}
				}
				return result;
			}),
		);
	}

	private addAdminMenuItems(vendor: IVendorModel): IMenuItem[] {
		let response: IMenuItem[] = [
			{
				title: 'Inventory',
				routeLink: this.navigationService.getInventoryRoute(),
				href: this.navigationService.getInventoryUrl(vendor.id),
				iconClass: 'mdi-package-variant-closed',
			},
			{
				title: 'Calculate Reorder',
				routeLink: this.navigationService.getCalculateReorderRoute(),
				href: this.navigationService.getCalculateReorderUrl(vendor.id),
				iconClass: 'rfid-ico-calc',
			},
			{
				title: 'Scan Sessions',
				routeLink: this.navigationService.getScanSessionsRoute(),
				href: this.navigationService.getScanSessionsUrl(vendor.id),
				iconClass: 'rfid-ico-scan-sessions',
			},
			{
				title: 'Orders',
				routeLink: this.navigationService.getOrdersRoute(DisplayOrderType.All),
				href: null,
				iconClass: 'rfid-ico-currency-usd',
			},
		];
		if (environment.oldSiteBaseAddress != null) {
			response = response.concat([
				{
					title: 'Printing',
					routeLink: [],
					href: this.navigationService.getPrintingUrl(vendor.id),
					iconClass: 'mdi-printer',
				},
			]);
		}

		response = response.concat([
			{
				title: 'Printing V2',
				routeLink: this.navigationService.getPrintingRoute(),
				href: null,
				iconClass: 'mdi-printer-outline',
				badge: 'New',
			},
			{
				title: 'Products',
				routeLink: this.navigationService.getProductsRoute(),
				href: this.navigationService.getProductsUrl(vendor.id),
				iconClass: 'mdi-tag-multiple-outline',
			},
			{
				title: 'Mobile Assets',
				routeLink: this.navigationService.getMobileAssetsRoute(),
				href: this.navigationService.getMobileAssetsUrl(vendor.id),
				iconClass: 'rfid-ico-mdi-assets',
			},
			{
				title: 'Locations',
				routeLink: this.navigationService.getLocationsRoute(),
				href: this.navigationService.getLocationsUrl(vendor.id),
				iconClass: 'mdi-map-marker-outline',
			},
		]);
		return response;
	}
	private addManagerMenuItems(vendor: IVendorModel): IMenuItem[] {
		let response: IMenuItem[] = [
			{
				title: 'Inventory',
				routeLink: this.navigationService.getInventoryRoute(),
				href: this.navigationService.getInventoryUrl(vendor.id),
				iconClass: 'mdi-package-variant-closed',
			},
			{
				title: 'Calculate Reorder',
				routeLink: this.navigationService.getCalculateReorderRoute(),
				href: this.navigationService.getCalculateReorderUrl(vendor.id),
				iconClass: 'rfid-ico-calc',
			},
			{
				title: 'Scan Sessions',
				routeLink: this.navigationService.getScanSessionsRoute(),
				href: this.navigationService.getScanSessionsUrl(vendor.id),
				iconClass: 'rfid-ico-scan-sessions',
			},
			{
				title: 'Orders',
				routeLink: this.navigationService.getOrdersRoute(DisplayOrderType.All),
				href: null,
				iconClass: 'rfid-ico-currency-usd',
			},
		];

		if (environment.oldSiteBaseAddress != null) {
			response = response.concat([
				{
					title: 'Printing',
					routeLink: [],
					href: this.navigationService.getPrintingUrl(vendor.id),
					iconClass: 'mdi-printer',
				},
			]);
		}

		response = response.concat([
			{
				title: 'Printing V2',
				routeLink: this.navigationService.getPrintingRoute(),
				href: null,
				iconClass: 'mdi-printer-outline',
				badge: 'New',
			},
			{
				title: 'Products',
				routeLink: this.navigationService.getProductsRoute(),
				href: this.navigationService.getProductsUrl(vendor.id),
				iconClass: 'mdi-tag-multiple-outline',
			},
			{
				title: 'Mobile Assets',
				routeLink: this.navigationService.getMobileAssetsRoute(),
				href: this.navigationService.getMobileAssetsUrl(vendor.id),
				iconClass: 'rfid-ico-mdi-assets',
			},
			{
				title: 'Locations',
				routeLink: this.navigationService.getLocationsRoute(),
				href: this.navigationService.getLocationsUrl(vendor.id),
				iconClass: 'mdi-map-marker-outline',
			},
		]);
		return response;
	}
	private addUserMenuItems(vendor: IVendorModel): IMenuItem[] {
		let response: IMenuItem[] = [
			{
				title: 'Inventory',
				routeLink: this.navigationService.getInventoryRoute(),
				href: this.navigationService.getInventoryUrl(vendor.id),
				iconClass: 'mdi-package-variant-closed',
			},
			{
				title: 'Scan Sessions',
				routeLink: this.navigationService.getScanSessionsRoute(),
				href: this.navigationService.getScanSessionsUrl(vendor.id),
				iconClass: 'rfid-ico-scan-sessions',
			},
			{
				title: 'Orders',
				routeLink: this.navigationService.getOrdersRoute(DisplayOrderType.All),
				href: null,
				iconClass: 'rfid-ico-currency-usd',
			},
		];
		if (environment.oldSiteBaseAddress != null) {
			response = response.concat([
				{
					title: 'Printing',
					routeLink: [],
					href: this.navigationService.getPrintingUrl(vendor.id),
					iconClass: 'mdi-printer',
				},
			]);
		}

		response = response.concat([
			{
				title: 'Printing V2',
				routeLink: this.navigationService.getPrintingRoute(),
				href: null,
				iconClass: 'mdi-printer-outline',
				badge: 'New',
			},
			{
				title: 'Products',
				routeLink: this.navigationService.getProductsRoute(),
				href: this.navigationService.getProductsUrl(vendor.id),
				iconClass: 'mdi-tag-multiple-outline',
			},
			{
				title: 'Locations',
				routeLink: this.navigationService.getLocationsRoute(),
				href: this.navigationService.getLocationsUrl(vendor.id),
				iconClass: 'mdi-map-marker-outline',
			},
		]);
		return response;
	}

	ngOnDestroy(): void {}

	ngOnInit(): void {}
}

interface IMenuItem {
	title: string;
	routeLink: string[];
	href: string | null;
	iconClass: string;
	badge?: string;
}
