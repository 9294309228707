import { Injectable } from '@angular/core';
import { IExcelHeader, IHeader } from '@shared/models';
import * as ExcelJS from 'exceljs';
import { Observable, from, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ExcelHelperService {
	constructor() {}

	public generateTemplate(headers: IHeader[], sheetName: string): Observable<Blob> {
		const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
		const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(sheetName);

		const rowIndex = 1;
		const row: ExcelJS.Row = worksheet.getRow(rowIndex);
		for (let i = 0; i < headers.length; i++) {
			const cellIndex = i + 1;
			const header = headers[i];
			const richText: ExcelJS.RichText[] = [
				{
					font: { bold: true },
					text: header.displayValue,
				},
			];

			if (header.required) {
				richText.push({
					font: { bold: true, color: { argb: 'FFFF0000' } },
					text: '*',
				});
			}

			const cell = row.getCell(cellIndex);

			cell.value = {
				richText: richText,
			};

			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'FFFFFF00' },
			};

			cell.border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			};
		}

		this.createOuterBorder(worksheet, { row: 1, col: 1 }, { row: 1, col: headers.length });
		this.autoFit(worksheet);

		return from(workbook.xlsx.writeBuffer()).pipe(
			map(_ => {
				return new Blob([_], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
			}),
		);
	}

	public getOriginHeaders(ws: ExcelJS.Worksheet): Record<string, number> {
		const headerRow: ExcelJS.Row = ws.getRow(1);
		const result: Record<string, number> = {};

		headerRow.eachCell((cell: ExcelJS.Cell) => {
			const index = cell.fullAddress.col;
			const text = cell.text.replace(/\*$/, '');
			result[text] = index;
		});

		return result;
	}

	public checkRequiredColumn(readedHeaders: IExcelHeader[], headers: IHeader[]): string[] | null {
		const errors: string[] = [];
		if (readedHeaders.length == 0) {
			errors.push('Header row not found.');
			return errors;
		}
		const requiredHeaders = headers.filter(_ => _.required);
		requiredHeaders.forEach(requiredHeader => {
			if (readedHeaders.filter(_ => _.displayValue === requiredHeader.displayValue).length == 0) {
				errors.push(`${requiredHeader.key} column is missing.`);
			}
		});
		return errors.length > 0 ? errors : null;
	}

	private autoFit(worksheet: ExcelJS.Worksheet) {
		worksheet.columns.forEach(function (column, i) {
			if (i !== 0 && column) {
				let maxLength = 0;
				column.eachCell!({ includeEmpty: true }, function (cell) {
					var columnLength = cell.value ? cell.value.toString().length : 10;
					if (columnLength > maxLength) {
						maxLength = columnLength;
					}
				});
				maxLength = maxLength + 2;
				column.width = Math.max(10, maxLength);
			}
		});
	}

	private createOuterBorder(
		worksheet: ExcelJS.Worksheet,
		start = { row: 1, col: 1 },
		end = { row: 1, col: 1 },
		borderWidth: ExcelJS.BorderStyle = 'medium',
	) {
		const borderStyle: Partial<ExcelJS.Border> = {
			style: borderWidth,
		};
		for (let i = start.row; i <= end.row; i++) {
			const leftBorderCell = worksheet.getCell(i, start.col);
			const rightBorderCell = worksheet.getCell(i, end.col);
			leftBorderCell.border = {
				...leftBorderCell.border,
				left: borderStyle,
			};
			rightBorderCell.border = {
				...rightBorderCell.border,
				right: borderStyle,
			};
		}

		for (let i = start.col; i <= end.col; i++) {
			const topBorderCell = worksheet.getCell(start.row, i);
			const bottomBorderCell = worksheet.getCell(end.row, i);
			topBorderCell.border = {
				...topBorderCell.border,
				top: borderStyle,
			};
			bottomBorderCell.border = {
				...bottomBorderCell.border,
				bottom: borderStyle,
			};
		}
	}
}
