export enum HandheldReadersOrderBy {
	Id = 'Id',
	ReaderModel = 'ReaderModel',
	SerialNumber = 'SerialNumber',
	Customer = 'Customer',
	FirstConnected = 'FirstConnected',
	LastConnected = 'LastConnected',
	Location = 'Location',
	UserName = 'UserName',
	Firmware = 'Firmware',
}
