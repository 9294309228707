import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { State } from '@shared/enums';

@Injectable({
	providedIn: 'root',
})
export class PrinterNotificationService {
	private hubConnection: signalR.HubConnection;

	private readonly stateChangedEventName = 'StateChanged';
	private readonly registeredEventName = 'Registered';
	private readonly deletedEventName = 'Deleted';

	constructor(private oidcSecurityService: OidcSecurityService) {
		const options: signalR.IHttpConnectionOptions = {
			accessTokenFactory: () => firstValueFrom(this.oidcSecurityService.getAccessToken()),
		};

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(environment.printServiceBaseAddress + '/signal/v1/printing/printers', options)
			.configureLogging(environment.sirnalRLogLevel)
			.build();
	}

	public startConnection = () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err));
	};

	public addPrinterStateChangedListener = (
		callback: (clientId: string, state: State, serialNumber?: string, issueDescription?: string) => void,
	) => {
		this.hubConnection.on(this.stateChangedEventName, ({ clientId, state, serialNumber, issueDescription }) => {
			callback(clientId, state, serialNumber, issueDescription);
		});
	};

	public addPrinterRegisteredListener = (callback: (clientId: string, serialNumber: string) => void) => {
		this.hubConnection.on(this.registeredEventName, (clientId: string, serialNumber: string) => {
			callback(clientId, serialNumber);
		});
	};

	public addPrinterDeletedListener = (callback: (clientId: string, serialNumber: string) => void) => {
		this.hubConnection.on(this.deletedEventName, (clientId: string, serialNumber: string) => {
			callback(clientId, serialNumber);
		});
	};

	public removeStateChangedListener = () => {
		this.hubConnection.off(this.stateChangedEventName);
	};

	public removeRegisteredListener = () => {
		this.hubConnection.off(this.registeredEventName);
	};

	public removeDeletedListener = () => {
		this.hubConnection.off(this.deletedEventName);
	};
}
