export enum OrderStatus {
	Draft = 'Draft',
	Cancelled = 'Cancelled',
	Shipping = 'Shipping',
	ShippingQC = 'ShippingQC',
	ShippedReceiving = 'ShippedReceiving',
	ReceivingQC = 'ReceivingQC',
	Completed = 'Completed',
}

export const OrderStatusDisplayValue: Record<string, string> = {
	Draft: 'Draft',
	Cancelled: 'Cancelled',
	Shipping: 'Shipping',
	ShippingQC: 'Shipping QC',
	ShippedReceiving: 'Shipped/Receiving',
	ReceivingQC: 'Receiving QC',
	Completed: 'Completed',
};
