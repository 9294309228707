import { Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/services';

@Injectable({
	providedIn: 'root',
})
export class UserLocalStorageService {
	constructor(private localStorageService: LocalStorageService) {}

	public get(userId: string): number | undefined {
		const vendorId: number | undefined = this.localStorageService.get<number>(this.getKey(userId));

		if (vendorId) {
			return vendorId;
		}

		return undefined;
	}

	public set(userId: string, vendorId: number): void {
		this.localStorageService.set<number>(this.getKey(userId), vendorId);
	}

	private getKey(userId: string): string {
		return userId + '_vendorId';
	}
}
