export enum Dpi {
	D203 = 203,
	D300 = 300,
	D600 = 600,
}

export const DpiDisplayValue: Record<string, string> = {
	D203: '203 dpi',
	D300: '300 dpi',
	D600: '600 dpi',
};
