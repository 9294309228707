import { FilterService } from 'primeng/api';
import { LocationType } from 'src/app/shared/enums';
import { LocationLightModel } from 'src/app/shared/models';

export abstract class BaseLocationFilter {
	protected _groupedLocations: IGroupedLocations[] = [];
	public filteredLocations: IGroupedLocations[] = [];

	constructor(public filterService: FilterService) {}

	public filterGroupedLocations(event: { query: string }) {
		let query = event.query;
		let filteredLocations: IGroupedLocations[] = [];

		for (let optgroup of this._groupedLocations) {
			let filteredSubOptions = this.filterService.filter(
				optgroup.items,
				['name', 'identifierKey'],
				query,
				'contains',
			);
			if (filteredSubOptions && filteredSubOptions.length) {
				filteredLocations.push({
					name: optgroup.name,
					value: optgroup.value,
					items: filteredSubOptions,
				});
			}
		}
		this.filteredLocations = filteredLocations;
	}

	public groupLocation(locations: LocationLightModel[], vendorId: number | undefined): IGroupedLocations[] {
		const groupedLocations: IGroupedLocations[] = [];
		if (vendorId) {
			const filteredByVendor = locations.filter(_ => _.vendorId === vendorId);

			let types: LocationType[] = [...new Set(filteredByVendor.map(item => item.type).sort())];
			types.forEach(type => {
				let groupedOption: IGroupedLocations = {
					name: type == LocationType.Dc ? type.toString().toUpperCase() : type.toString(),
					value: type,
					items: filteredByVendor
						.filter(_ => _.type == type)
						.sort((a, b) => (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1)),
				};
				groupedLocations.push(groupedOption);
			});
		}
		return groupedLocations;
	}
}

interface IGroupedLocations {
	name: string;
	value: LocationType;
	items: LocationLightModel[];
}
