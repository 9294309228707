import * as moment from 'moment';
export class DateHelper {
	private static dateBefore = new Date(1990, 1, 1);
	private static dateAfter = new Date(2100, 12, 31);

	//TODO: need to refactor ProductViewModel, date came from input as a string
	public static isValid(date: Date): boolean {
		if (moment(date).isValid()) {
			const parsedDate = new Date(date);
			return parsedDate > this.dateBefore && parsedDate < this.dateAfter;
		}
		return false;
	}
}
