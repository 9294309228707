import { HttpParams, HttpResponse } from '@angular/common/http';
import { OffsetCollection } from '@shared/models';

export class HttpUtils {
	public getParams(paramsObject: object): HttpParams {
		return new HttpParams({ fromObject: { ...paramsObject } });
	}

	public toOffsetCollection<T>(response: HttpResponse<T[]>): OffsetCollection<T> {
		const totalCount = response.headers.get('x-pagination-total');
		const array = response.body ?? [];
		const result = array as OffsetCollection<T>;
		result.totalCount = +(totalCount ?? 0);
		return result;
	}
}
