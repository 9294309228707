import { Injectable } from '@angular/core';
import { ILocationRequest } from '@core/models';
import { environment } from '@environments/environment';
import { ILocationLightModel, OffsetCollection, OffsetPagination } from '@shared/models';
import { ChunkService } from '@shared/services';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LocationService {
	private _baseUrl: string;

	constructor(private chunkService: ChunkService) {
		this._baseUrl = `${environment.portalBaseAddress}`;
	}

	public getAll(request: ILocationRequest): Observable<OffsetCollection<ILocationLightModel>> {
		const url = `${this._baseUrl}/api/v1/locations`;
		return this.chunkService.getAll<ILocationLightModel, ILocationRequest>(request, url).pipe(shareReplay());
	}

	public getMany(request: ILocationRequest, pager: OffsetPagination): Observable<OffsetCollection<ILocationLightModel>> {
		const url = `${this._baseUrl}/api/v1/locations`;
		return this.chunkService.get<ILocationLightModel, ILocationRequest>(pager, request, url).pipe(shareReplay());
	}
}
