import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUpdateStatusRequest } from '@core/models';
import { environment } from '@environments/environment';
import { PrintingDocumentStatus } from '@shared/enums';
import { OffsetCollection } from '@shared/models';
import { ChunkService } from '@shared/services';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PrintingService {
	private printServiceUrl: string;

	constructor(
		private http: HttpClient,
		private chunkService: ChunkService,
	) {
		this.printServiceUrl = environment.printServiceBaseAddress;
	}

	public pauseAll(clientId: string, serialNumber: string): Observable<any> {
		const documentStatuses = [
			PrintingDocumentStatus.Pending,
			PrintingDocumentStatus.Ready,
			PrintingDocumentStatus.InProgress,
		];
		return this.getMany(documentStatuses, clientId, serialNumber).pipe(
			switchMap(_ => {
				if (_.length > 0) {
					const request: IUpdateStatusRequest = {
						documentIds: _.map(_ => _.documentId),
					};
					return this.pause(clientId, serialNumber, request);
				}
				return of(_);
			}),
		);
	}

	public pause(clientId: string, serialNumber: string, request: IUpdateStatusRequest): Observable<any> {
		return this.http.put(
			`${this.printServiceUrl}/api/v1/stations/${clientId}/printers/${serialNumber}/queue/pause`,
			request,
		);
	}

	private getMany(
		statuses: PrintingDocumentStatus[],
		clientId: string,
		serialNumber: string,
	): Observable<OffsetCollection<PrintingDocumentResponse>> {
		const request: IDocumentRequest = {
			documentStatuses: statuses,
		};
		const url = `${this.printServiceUrl}/api/v1/stations/${clientId}/printers/${serialNumber}/queue`;

		return this.chunkService.getAll<PrintingDocumentResponse, IDocumentRequest>(request, url);
	}
}

interface IDocumentRequest {
	documentStatuses: PrintingDocumentStatus[];
}

interface PrintingDocumentResponse {
	documentId: string;
	clientId: string;
	printerSerialNumber: string;
	zplBody: string;
	status: PrintingDocumentStatus;
}
