import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BreadcrumbService {
	public crumbs$: BehaviorSubject<MenuItem[]>;

	constructor() {
		const initValue: MenuItem[] = [];
		this.crumbs$ = new BehaviorSubject(initValue);
	}

	set(items: MenuItem[]) {
		this.crumbs$.next(items);
	}

	clear() {
		this.crumbs$.next([]);
	}
}
