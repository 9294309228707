export enum PrintingJobStatus {
	Postponed = 'Postponed',
	Ready = 'Ready', //ready for print ASAP
	InProgress = 'InProgress', //printing right now
	Completed = 'Completed', //printing process completed
}

export const PrintingJobStatusDisplayValue: Record<string, string> = {
	Postponed: 'Postponed',
	Ready: 'Ready',
	InProgress: 'In Progress',
	Completed: 'Completed',
};
