import { ErrorHandler, Injectable } from '@angular/core';
import { AppMessageService } from '@core/services';
import { CustomErrorMessage, CustomErrors } from '@shared/models';

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private _appMessageService: AppMessageService) {}

	//The error must be of any type, since here we catch all errors including errors thrown by angular
	handleError(error: any): void {
		const isSimpleRfidException = !!error.error?.key && !!error.error?.code;

		let errorMessage = undefined;

		if (isSimpleRfidException) {
			const dictionaryKey: CustomErrors = error.error.code;
			errorMessage = CustomErrorMessage[dictionaryKey];
		}

		if (errorMessage == undefined) {
			console.error(error.message);
			errorMessage = 'Unknown Error. Please contact with administrator.';
		}

		this._appMessageService.error(errorMessage);
	}
}
