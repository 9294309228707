@if (permissions$ | async; as permissions) {
	<div class="permissions-selection">
		<div class="vendor-permissions-wrapper">
			<customer-search (addCustomerId)="addCustomer($event)" [selectedIds]="getSelectedCustomerIds()">
			</customer-search>
			<div class="vendor-permissions-wrapper list-wrapper">
				<ng-container
					*ngTemplateOutlet="
						customerPermissionBlock;
						context: {
							$implicit: permissions | customerPermissionFilter: customerKeyword,
						}
					"
				>
				</ng-container>
				<ng-template #customerPermissionBlock let-filteredPermissions>
					@if (filteredPermissions.length == 0) {
						<div class="empty">Please, select the Vendor first.</div>
					} @else {
						@if (selectedCustomer$ | async; as selectedCustomer) {
							<div class="vendor-permissions-list">
								<ul class="scroller">
									@for (item of filteredPermissions; track item) {
										<li
											class="list-item"
											(click)="selectedCustomer$.next(item)"
											[ngClass]="{ active: item.id == selectedCustomer.id }"
										>
											@if (item.isChanged) {
												<div class="marker"></div>
											}
											<span>{{ item.name }}</span>
											<div class="control-input-checkbox">
												<p-checkbox
													gaModelChangeEvent="Manager access changed"
													stop-redirect
													[binary]="true"
													[(ngModel)]="item.isChecked"
													(onChange)="onCustomerChange(item)"
													#vendors
												></p-checkbox>
												<label>Manager</label>
											</div>
											@if (permissions.length > 1) {
												<i
													class="close-icon remove-btn-position mdi mdi-close"
													(click)="removeCustomer(item)"
												></i>
											}
										</li>
									}
								</ul>
							</div>
						}
					}
					<div class="vendor-permissions-wrapper"></div>
				</ng-template>
			</div>
		</div>
		<div class="locations-permissions-wrapper">
			<div class="control-input-container without-label">
				<div class="input-box input-icon-absolute-right">
					<input
						class="control-input"
						type="text"
						placeholder="Type to search location..."
						[(ngModel)]="locationKeyword"
					/>
					<i class="mdi mdi-magnify static-icon"></i>
				</div>
			</div>

			@if (selectedCustomer$ | async; as selectedCustomer) {
				<div class="list-wrapper">
					<div class="selected-vendor-name"><span>Customer:</span> {{ selectedCustomer.name }}</div>
					@if (selectedCustomer.locationTypes == null || selectedCustomer.isChecked) {
						<div class="no-locaions">
							@if (selectedCustomer.locationTypes == null) {
								<div>Customer has no locations</div>
							}
							@if (selectedCustomer.isChecked && selectedCustomer.locationTypes !== null) {
								<div>Full access</div>
							}
						</div>
					} @else {
						<ng-container
							*ngTemplateOutlet="
								locationTypes;
								context: {
									$implicit: selectedCustomer.locationTypes | locationTypeFilter: locationKeyword,
								}
							"
						>
						</ng-container>
						<ng-template #locationTypes let-locationTypes>
							@if (locationTypes.length == 0) {
								<div class="empty">Nothing found</div>
							} @else {
								<div class="accordion-wrapper">
									<div class="scroller">
										<p-accordion class="w-full">
											@for (
												locationTypePermission of locationTypes;
												track locationTypePermission
											) {
												<p-accordionTab>
													<ng-template pTemplate="header">
														<div class="accordion-tab-header-content">
															@if (locationTypePermission.isChanged) {
																<div class="marker"></div>
															}
															<span class="vertical-align-middle">{{
																locationTypePermission.locationType
															}}</span>
															<div class="control-input-checkbox">
																<p-checkbox
																	gaModelChangeEvent="Access to all locations changed (User Permissions)"
																	stop-redirect
																	[binary]="true"
																	[(ngModel)]="locationTypePermission.isChecked"
																	(onChange)="
																		onLocationChange(
																			locationTypePermission,
																			selectedCustomer
																		)
																	"
																></p-checkbox>
																<label>Access to all locations</label>
															</div>
														</div>
													</ng-template>
													<ng-template pTemplate="content">
														<ul class="location-permissions-list">
															@for (
																location of locationTypePermission.locations
																	| locationFilter: locationKeyword;
																track location
															) {
																<li>
																	<div class="control-input-checkbox">
																		@if (!locationTypePermission.isChecked) {
																			<p-checkbox
																				gaModelChangeEvent="Access to location changed (User Permissions)"
																				[binary]="true"
																				[(ngModel)]="location.isChecked"
																				(onChange)="
																					onLocationChange(
																						locationTypePermission,
																						selectedCustomer
																					)
																				"
																			></p-checkbox>
																		}
																		<label>{{ location.name }}</label>
																	</div>
																</li>
															}
														</ul>
													</ng-template>
												</p-accordionTab>
											}
										</p-accordion>
									</div>
								</div>
							}
						</ng-template>
					}
				</div>
			} @else {
				<div class="list-wrapper">
					<div class="empty">Please, select the Customer first.</div>
				</div>
			}
		</div>
	</div>
}
