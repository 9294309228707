export abstract class BaseHeadersModel {
	[key: string]: number | null;
	sku: number | null = null;
	quantity: number | null = null;
}

export class PrintingProductHeadersModel extends BaseHeadersModel {
	lotNumber: number | null = null;
	expDate: number | null = null;
	cost: number | null = null;
	bornDate: number | null = null;
	serialNumber: number | null = null;
	sellBy: number | null = null;
}

export class OrderHeadersModel extends BaseHeadersModel {
	orderNumber: number | null = null;
	origin: number | null = null;
	destination: number | null = null;
	lotNumber: number | null = null;
	expDate: number | null = null;
	cost: number | null = null;
	bornDate: number | null = null;
	serialNumber: number | null = null;
	sellBy: number | null = null;
}
