import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DownloadService {
	constructor(private http: HttpClient) {}
	public download(url: string): Observable<Blob> {
		return this.http
			.get(url, {
				responseType: 'blob',
			})
			.pipe(
				tap(_ => {
					const sizeMb = Math.round(_.size / 1024 / 1024);
					if (sizeMb > 100) {
						console.warn('File size is too big', sizeMb, 'Mb');
					}
				}),
			);
	}
}
