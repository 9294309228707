import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, Observable, of, share, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LabelaryZplService {
	constructor(private httpClient: HttpClient) {}

	getLabelImage(dpi: number, width: number, height: number, zplString: string): Observable<string | null> {
		let desiredPrintDensity = this.getPrintDensity(dpi);

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
			Accept: 'image/png',
		});

		const url = `https://api.labelary.com/v1/printers/${desiredPrintDensity}dpmm/labels/${width}x${height}/0/`;

		return this.httpClient
			.post(url, zplString, {
				headers: headers,
				responseType: 'arraybuffer',
				observe: 'response',
			})
			.pipe(
				switchMap((response: HttpResponse<ArrayBuffer>) => {
					const blob = new Blob([response.body!], { type: 'image/png' });

					const fileReader = new FileReader();
					const loadEvent$: Observable<string> = new Observable<string>(subscriber => {
						fileReader.onloadend = (event: Event) => {
							const base64Data = fileReader.result as string;
							subscriber.next(base64Data);
							subscriber.complete();
						};
					});

					fileReader.readAsDataURL(blob);

					return loadEvent$;
				}),
				catchError(err => {
					console.error(err);
					return of(null);
				}),
				share(),
			);
	}

	private getPrintDensity(dpi: number) {
		let desiredPrintDensity: number;

		switch (dpi) {
			case 203:
				desiredPrintDensity = 8;
				break;
			case 300:
				desiredPrintDensity = 12;
				break;
			case 600:
				desiredPrintDensity = 24;
				break;
			default:
				throw new RangeError('Incorrect DPI. ');
		}
		return desiredPrintDensity;
	}
}
