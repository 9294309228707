import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomerStatuses } from '@shared/enums';
import { CustomerLightModel } from '@shared/models';
import { ChunkService } from '@shared/services';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	private readonly _vendorStatuses: CustomerStatuses[] = [CustomerStatuses.Standard, CustomerStatuses.Trial];
	private _baseUrl: string;
	private _vendors$: BehaviorSubject<CustomerLightModel[]> = new BehaviorSubject<CustomerLightModel[]>([]);

	constructor(private _chunkService: ChunkService) {
		this._baseUrl = `${environment.portalBaseAddress}`;
		this.privateGetVendors();
	}

	public getVendors(): Observable<CustomerLightModel[]> {
		return this._vendors$.asObservable();
	}

	private privateGetVendors(): void {
		const request: ICustomerRequest = {
			statuses: this._vendorStatuses,
		};
		const url = `${this._baseUrl}/api/v1/vendors`;
		this._chunkService.getAll<ICustomerLightResponse, ICustomerRequest>(request, url).subscribe(res => {
			this._vendors$.next(res);
		});
	}
}

interface ICustomerLightResponse {
	id: number;
	name: string;
	abbreviation?: string;
}

interface ICustomerRequest {
	statuses: CustomerStatuses[];
}
