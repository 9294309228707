import { Component, Input } from '@angular/core';

@Component({
	selector: 'table-headers',
	templateUrl: './table-headers.component.html',
	styleUrl: './table-headers.component.scss',
})
export class TableHeadersComponent {
	@Input() headers: any[] = [];
}
