import { Component, Input } from '@angular/core';

@Component({
	selector: 'skeleton',
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
	@Input() minWidth: number = 0;
	@Input() maxWidth: number = 300;
	@Input() height: number = 35;
	@Input() leftBorder: boolean = true;
}
