<p-overlayPanel #customersDD>
	<ng-template pTemplate="content">
		@if (!isLoading) {
			<div class="scroller">
				<ng-container
					*ngTemplateOutlet="
						customerBlock;
						context: {
							$implicit:
								customers | customerByKeywordFilter: searchKeyword | customerByIdsFilter: selectedIds,
						}
					"
				></ng-container>
				<ng-template #customerBlock let-customers>
					@if (customers.length !== 0) {
						<div class="notranslate">
							@for (item of customers; track item) {
								<div
									class="list-item"
									gaEvent="Customer added from search"
									(click)="onCustomerSelect(item)"
								>
									{{ item.name }}
								</div>
							}
						</div>
					} @else {
						<span> Nothing Found </span>
					}
				</ng-template>
			</div>
		} @else {
			<div class="skeleton-rows">
				<skeleton [height]="18"></skeleton>
				<skeleton [height]="18"></skeleton>
				<skeleton [height]="18"></skeleton>
			</div>
		}
	</ng-template>
</p-overlayPanel>

<div class="control-input-container without-label">
	<div class="input-box input-icon-absolute-right">
		<i class="mdi mdi-magnify static-icon"></i>

		<input
			placeholder="Type to search customer..."
			class="control-input without-label"
			type="text"
			(click)="customersDD.show($event)"
			[(ngModel)]="searchKeyword"
			[disabled]="disabled"
		/>
	</div>
</div>
