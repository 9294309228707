import { Injectable } from '@angular/core';
import { Role } from '@shared/enums';
import { UserContextService } from '@shared/services';
import { TokenHelper } from '@shared/util/token-helper';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, filter, map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserRoleService {
	private readonly _userRole$: Observable<Role>;

	constructor(private _oidcSecurityService: OidcSecurityService, private _userContextService: UserContextService) {
		this._userRole$ = combineLatest({
			userContext: this._userContextService.getVendor(),
			token: this._oidcSecurityService.checkAuth(),
		}).pipe(
			filter(result => result.token.userData != null),
			map(_ => {
				return TokenHelper.getRole(_.token.userData, _.userContext.id);
			}),
		);
	}

	public getRole() {
		return this._userRole$;
	}
}
