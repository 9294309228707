import { Injectable } from '@angular/core';
import { ExcelHelperService } from '@shared/services/excel-helper.service';
import * as ExcelJS from 'exceljs';

@Injectable({
	providedIn: 'root',
})
export class DocumentHeadersService {
	constructor(private excelHelper: ExcelHelperService) {}

	public get(workbook: ExcelJS.Workbook): Record<string, number> {
		const ws = workbook.worksheets[0];
		return this.excelHelper.getOriginHeaders(ws);
	}
}
