<table class="skeleton-table">
	<thead>
		<tr class="main-table-row">
			@for (col of colsArray; track col) {
				<th>
					<skeleton [minWidth]="60" [height]="18"></skeleton>
				</th>
			}
		</tr>
	</thead>
	<tbody>
		@for (col of rowsArray; track col) {
			<tr>
				@for (col of colsArray; track col) {
					<td>
						<skeleton [minWidth]="60" [height]="18"></skeleton>
					</td>
				}
			</tr>
		}
	</tbody>
</table>
