@if (count > 1) {
	<div class="control-input-container">
		<label>Customer</label>
		<div class="input-box">
			<p-autoComplete
				gaModelChangeEvent="Customer filter changed"
				[(ngModel)]="selectedCustomer"
				[suggestions]="filteredCustomers"
				[showClear]="showClear"
				(completeMethod)="filterCustomers($event)"
				(onSelect)="onCustomerSelect($event.value)"
				field="name"
				panelStyleClass="notranslate"
				placeholder="Select Customer"
				[dropdown]="true"
				[forceSelection]="true"
				(onClear)="onClear()"
			>
			</p-autoComplete>
		</div>
	</div>
}
