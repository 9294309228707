import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role } from '@shared/enums';
import { UserContextService } from '@shared/services';
import { NavigationService } from '@shared/services/navigation.service';
import { UserRoleService } from '@shared/services/user-role.service';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RedirectAdminToManagerGuard implements CanActivate {
	constructor(
		private userRoleService: UserRoleService,
		private router: Router,
		private navigationService: NavigationService,
		private userContextService: UserContextService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const url: string = state.url;

		return combineLatest({
			role: this.userRoleService.getRole(),
			vendor: this.userContextService.getVendor(),
		}).pipe(
			map(resp => {
				//redirect from customer profile to edit customer
				if (resp.role === Role.Administrator && url.includes('manager/customer-profile')) {
					var editCustomerUrl = this.navigationService.getEditCustomerRoute(resp.vendor.id).join('/');
					return this.router.parseUrl(editCustomerUrl);
				}

				// redirect admin if he go to manager page
				if (resp.role === Role.Administrator && url.includes('manager')) {
					return this.router.parseUrl(url.replace('manager', 'admin'));
				}

				return true;
			}),
		);
	}
}
