import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LocalStorageKeyService } from '@core/services/local-storage-key.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginatorComponent } from '@shared/components';
import { OffsetPagination } from '@shared/models';
import { LocalStorageService } from '@shared/services';
import { Observable, Subject, combineLatest, of, switchMap } from 'rxjs';

@UntilDestroy()
@Directive({
	selector: '[savePagerState]',
	providers: [LocalStorageKeyService],
})
export class PagerSaveStateDirective implements OnInit {
	private _key$: Observable<string>;
	private _onChange$ = new Subject<OffsetPagination>();

	@Input()
	public set pager(val: OffsetPagination | null) {
		this._pagerComponent$.pager = val;
	}

	public get pager(): OffsetPagination | null {
		return this._pagerComponent$.pager;
	}

	@Output() onPagerChange = new EventEmitter<OffsetPagination>();

	constructor(
		private _pagerComponent$: PaginatorComponent,
		_localStorageKeyService: LocalStorageKeyService,
		private _localStorageService: LocalStorageService,
	) {
		this._key$ = _localStorageKeyService.fromCurrentRoute('size');

		this._onChange$
			.pipe(
				switchMap(changedPager => {
					return combineLatest({
						key: this._key$,
						changedPager: of(changedPager),
					});
				}),
				untilDestroyed(this),
			)
			.subscribe(resp => {
				this._localStorageService.set(resp.key, resp.changedPager.take);
				this.onPagerChange.emit(resp.changedPager);
			});
	}

	public ngOnInit(): void {
		this._key$.pipe(untilDestroyed(this)).subscribe(key => {
			const take = this._localStorageService.get(key);
			if (take) {
				this.onPagerChange.emit(new OffsetPagination(0, +take));
			}
		});
	}

	@HostListener('pagerChange', ['$event']) onChange(pager: OffsetPagination): void {
		this._onChange$.next(pager);
	}
}
