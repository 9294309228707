import { IStoredHeaderSettings } from '@core/models';
import { LocalStorageService } from '@shared/services';

export abstract class StoredHeadersService {
	constructor(
		private _localStorageKey: string,
		protected _localStorageService: LocalStorageService,
	) {
		this._localStorageKey = _localStorageKey;
	}

	public get(): IStoredHeaderSettings {
		let savedBinding: IStoredHeaderSettings | undefined = this._localStorageService.get<IStoredHeaderSettings>(
			this._localStorageKey,
		);

		if (!savedBinding) {
			savedBinding = {
				headers: null,
				hasColumnTitles: true,
			};
		}

		return savedBinding;
	}

	public set(model: IStoredHeaderSettings) {
		this._localStorageService.set<IStoredHeaderSettings>(this._localStorageKey, model);
	}
}
