export enum OrderProperty {
	orderNumber = 'orderNumber',
	origin = 'origin',
	destination = 'destination',
	sku = 'sku',
	quantity = 'quantity',
	lotNumber = 'lotNumber',
	expDate = 'expDate',
	cost = 'cost',
	bornDate = 'bornDate',
	serialNumber = 'serialNumber',
	sellBy = 'sellBy',
}
