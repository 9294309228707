import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
	CustomerFilterComponent,
	CustomerSearchComponent,
	CustomerSelectorComponent,
	LocationFilterComponent,
	LocationSelectorComponent,
	ManagerCustomerSelectorComponent,
	PrintingProductExcelReaderComponent,
	ProductSelectorComponent,
	SetupPermissionsComponent,
} from '@core/components';
import { ExcelReaderDirective, PagerSaveStateDirective } from '@core/directives';
import {
	CustomerFilterByIdsPipe,
	CustomerFilterByKeywordPipe,
	CustomerPermissionFilterPipe,
	LocationFilterPipe,
	LocationTypeFilterPipe,
} from '@core/pipes';
import { PrintJobNotificationService } from '@core/services';
import { GaModelChangeEventDirective } from '@shared/directives';
import SharedModule from '@shared/shared.module';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxRerenderModule } from 'ngx-rerender';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
	declarations: [
		CustomerPermissionFilterPipe,
		LocationFilterPipe,
		LocationTypeFilterPipe,
		CustomerFilterByKeywordPipe,
		CustomerFilterByIdsPipe,
		PagerSaveStateDirective,
		LocationSelectorComponent,
		LocationFilterComponent,
		PrintingProductExcelReaderComponent,
		ExcelReaderDirective,
		ProductSelectorComponent,
		SetupPermissionsComponent,
		CustomerFilterComponent,
		CustomerSelectorComponent,
		ManagerCustomerSelectorComponent,
		CustomerSearchComponent,
	],
	//TODO: move UI module to shared
	imports: [
		CommonModule,
		AutoCompleteModule,
		NgxGoogleAnalyticsModule,
		GaModelChangeEventDirective,
		SharedModule,
		FormsModule,
		MessageModule,
		FileUploadModule,
		DropdownModule,
		ProgressSpinnerModule,
		NgxRerenderModule,
		OverlayPanelModule,
	],
	exports: [
		CustomerPermissionFilterPipe,
		LocationFilterPipe,
		LocationTypeFilterPipe,
		CustomerFilterByKeywordPipe,
		CustomerFilterByIdsPipe,
		PagerSaveStateDirective,
		LocationSelectorComponent,
		LocationFilterComponent,
		PrintingProductExcelReaderComponent,
		ExcelReaderDirective,
		ProductSelectorComponent,
		SetupPermissionsComponent,
		CustomerFilterComponent,
		CustomerSelectorComponent,
		ManagerCustomerSelectorComponent,
		CustomerSearchComponent,
	],
	providers: [PrintJobNotificationService],
})
export class CoreModule {}
