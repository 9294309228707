<div class="paginator-container">
	<div class="__lines-per-page-box">
		<span>Lines per page</span>
		<input
			*mcRerender="rerenderTrigger"
			#linesInput
			pTooltip="1 - 100"
			tooltipPosition="top"
			class="control-input without-label"
			[ngModel]="pager$.value.take"
			[type]="inputTypeEnum.number"
			(keypress)="validatePositive($event)"
			(ngModelChange)="onPageChange({ first: 0, rows: $event })"
			min="1"
			max="100"
		/>
	</div>
	<p-paginator
		(onPageChange)="onPageChange($event)"
		[showJumpToPageInput]="showJumpToPageInput"
		[first]="pager$.value.offset"
		[rows]="pager$.value.take"
		[totalRecords]="totalCount$.value"
	></p-paginator>
</div>
