export class OffsetPagination {
	public offset: number;
	public take: number;

	constructor(offset: number, take: number) {
		this.offset = offset;
		this.take = take;
	}

	static Default() {
		return new OffsetPagination(0, 100);
	}

	static First() {
		return new OffsetPagination(0, 1);
	}

	static None() {
		return new OffsetPagination(0, 100000);
	}
}
