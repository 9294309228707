<div class="control-input-container without-label">
	@if (loading$ | async; as loading) {
		<skeleton></skeleton>
	} @else {
		@if (isLocked) {
			<div class="input-box notranslate">
				@if (location) {
					<span class="readonly-input">{{ location.type }} - {{ location.name }}</span>
				} @else {
					<span class="readonly-input">N/A</span>
				}
			</div>
		} @else {
			<div class="input-box">
				<p-autoComplete
					gaModelChangeEvent="Location selector changed"
					[group]="true"
					[(ngModel)]="location"
					[suggestions]="filteredLocations"
					[showClear]="showClear"
					(completeMethod)="filterGroupedLocations($event)"
					(onSelect)="locationChange.emit($event.value)"
					field="name"
					[dropdown]="true"
					panelStyleClass="notranslate"
					placeholder="Select Location"
					[disabled]="!vendorId"
					appendTo="body"
				>
					<ng-template let-group pTemplate="group">
						<div class="flex align-items-center">
							<span>{{ group.name }}</span>
						</div>
					</ng-template>
				</p-autoComplete>
			</div>
		}
	}
</div>
