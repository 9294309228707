import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TitleService } from './shared/services/title.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	constructor(
		private primengConfig: PrimeNGConfig,
		private titleService: TitleService,
	) {
		this.titleService.initialize();
	}

	ngOnInit(): void {
		this.primengConfig.ripple = true;
	}

	ngOnDestroy(): void {}
}
