import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role } from '@shared/enums';
import { NavigationService, UserRoleService } from '@shared/services';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AdministratorAuthGuard implements CanActivate {
	constructor(
		private userRoleService: UserRoleService,
		private router: Router,
		private navigationService: NavigationService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.userRoleService.getRole().pipe(
			map(role => {
				// allow navigation if user is administrator
				if (role === Role.Administrator) {
					return true;
				}

				// redirect if user is not administrator
				return this.router.parseUrl(this.navigationService.getForbiddenRoute());
			}),
		);
	}
}
