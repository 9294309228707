import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PrintStationService } from '@core/services';
import { LocationNameService } from '@core/services/location';
import { environment } from '@environments/environment';
import { IPrinterViewModel, IPrintStationDetailsModel } from '@printing/models';
import { ChunkService, MetaDataService } from '@shared/services';
import { RecordHelper } from '@shared/util';
import { HttpUtils } from '@shared/util/http-utils';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { IPrinterFilter } from '@core/models';
import { OffsetPagination } from '@shared/models';

@Injectable({
	providedIn: 'root',
})
export class PortalPrintingService {
	private portalUrl: string;
	private httpUtils: HttpUtils = new HttpUtils();

	constructor(
		private http: HttpClient,
		private printService: PrintStationService,
		private chunkService: ChunkService,
		private locationNameService: LocationNameService,
		private metaDataService: MetaDataService,
	) {
		this.portalUrl = environment.portalBaseAddress;
	}

	public getPrinterById(id: number, customerId: number): Observable<IPrinterViewModel> {
		const printerFilter: IPrinterFilter = {
			ids: [id],
			clientIds: [],
			serialNumbers: [],
		};

		return this.printService.getManyPrinters(printerFilter, OffsetPagination.First()).pipe(
			map(_ => _[0]),
			switchMap(printer => {
				const printStation$ = this.printService.getPrintStations([printer.clientId]).pipe(map(_ => _[0]));

				const request: IPrintStationLocationDataRequest = {
					//Todo: Rename vendorId to customerId after global renaming vendor to customer
					vendorId: customerId,
					clientIds: [printer.clientId],
					ids: [],
					hasLocationAssigned: true,
				};
				const printerLocation$ = this.getPortalPrintStations(OffsetPagination.First(), request).pipe(
					map(_ => _[0]),
				);

				return combineLatest({
					printStation: printStation$,
					printerLocation: printerLocation$,
				}).pipe(
					map(response => {
						const location = this.locationNameService.findLocationByKey(
							response.printerLocation.locationKey!,
						);
						const a: IPrinterViewModel = {
							...printer,
							locationKey: response.printerLocation.locationKey!,
							locationName: location?.name ?? 'N/A',
							locationType: location?.type ?? 'N/A',
							printStationId: response.printerLocation.id!,
							printStationName: response.printStation.name,
							printStationStatus: response.printStation.status,
						};
						return a;
					}),
				);
			}),
		);
	}

	public getAllPrinters(vendorId: number): Observable<IPrinterViewModel[]> {
		const request: IPrintStationLocationDataRequest = {
			vendorId: vendorId,
			clientIds: [],
			ids: [],
			hasLocationAssigned: true,
		};
		const url = `${this.portalUrl}/api/v1/printing/stations`;

		return this.chunkService
			.getAll<IPrintStationLocationDataResponse, IPrintStationLocationDataRequest>(request, url)
			.pipe(
				switchMap(stations => {
					const clientIds: string[] = stations.map(_ => _.clientId);
					if (clientIds.length == 0) {
						return of([]);
					}

					const clientLocationDictionary = stations.reduce<Record<string, IPrintStationLocationDataResponse>>(
						(previous, current) => {
							previous[current.clientId] = current;
							return previous;
						},
						{},
					);

					//we need to wait for the locations to load. in order to assign the location names to the printers
					const locations$ = this.metaDataService.getLocations();
					const printers$ = this.printService.getAllPrinters(clientIds);
					const stations$ = this.printService.getPrintStations(clientIds);
					return combineLatest({
						locations: locations$,
						printers: printers$,
						stations: stations$,
					}).pipe(
						filter(_ => {
							return _.locations.length > 0;
						}),
						map(response => {
							return response.printers
								.filter(_ => clientIds.includes(_.clientId))
								.map<IPrinterViewModel>(printer => {
									const stationsDictionary = RecordHelper.toRecord(response.stations, 'clientId');
									const stationLocationData = clientLocationDictionary[printer.clientId];
									const location = this.locationNameService.findLocationByKey(
										stationLocationData.locationKey!,
									);
									const station = stationsDictionary[printer.clientId];
									return {
										...printer,
										locationKey: stationLocationData.locationKey!,
										locationType: location?.type ?? 'N/A',
										locationName: location?.name ?? 'N/A',
										printStationName: stationLocationData.displayName,
										printStationId: stationLocationData.id!,
										printStationStatus: station.status,
									};
								});
						}),
					);
				}),
			);
	}

	public getPrintStationDetails(id: number): Observable<IPrintStationDetailsModel> {
		return this.http.get<IPrintStationDetailsResponse>(`${this.portalUrl}/api/v1/printing/stations/${id}`);
	}

	private getPortalPrintStations(pager: OffsetPagination, filter: IPrintStationLocationDataRequest) {
		const url = `${this.portalUrl}/api/v1/printing/stations`;
		return this.chunkService.get<IPrintStationLocationDataResponse, IPrintStationLocationDataRequest>(
			pager,
			filter,
			url,
		);
	}
}

interface IPrintStationLocationDataRequest {
	vendorId: number;
	clientIds: string[];
	ids: number[];
	hasLocationAssigned: boolean | null;
}

interface IPrintStationDetailsResponse {
	id: number;
	clientId: string;
	vendorId: number;
	locationKey: string;
	loginAnyDesk: string;
	passwordAnyDesk: string;
	loginWin: string;
	passwordWin: string;
	lockEpc: boolean;
	autoUpdate: boolean;
	epcVerification: boolean;
	displayName: string;
}

interface IPrintStationLocationDataResponse {
	id: number | null;
	clientId: string;
	vendorId: number | null;
	locationKey: string | null;
	displayName: string;
}
