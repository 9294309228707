import { Injectable } from '@angular/core';

import { PrintingService } from '@core/services';
import { Observable, switchMap } from 'rxjs';
import { environment } from '@environments/environment';
import { PrintClientCommandParams, PrintClientCommandType } from '@core/enums/log-event-level';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class PrintStationCommandService {
	private _printServiceUrl: string;
	constructor(
		private http: HttpClient,
		private _printingService: PrintingService,
	) {
		this._printServiceUrl = environment.printServiceBaseAddress;
	}

	public calibrate(clientId: string, serialNumber: string): Observable<any> {
		const request: PrintClientCommandRequest = {
			commandType: PrintClientCommandType.Calibrate,
			commandParams: { [PrintClientCommandParams.SerialNumber]: serialNumber },
		};

		return this.pausePrintingAndSendCommand(clientId, serialNumber, request);
	}

	public delete(clientId: string, serialNumber: string): Observable<any> {
		const request: PrintClientCommandRequest = {
			commandType: PrintClientCommandType.DeletePrinter,
			commandParams: {
				[PrintClientCommandParams.SerialNumber]: serialNumber,
				[PrintClientCommandParams.ClientId]: clientId,
			},
		};

		return this.pausePrintingAndSendCommand(clientId, serialNumber, request);
	}

	private pausePrintingAndSendCommand(
		clientId: string,
		serialNumber: string,
		request: PrintClientCommandRequest,
	): Observable<any> {
		return this._printingService.pauseAll(clientId, serialNumber).pipe(
			switchMap(_ => {
				return this.post(clientId, request);
			}),
		);
	}

	private post(clientId: string, request: PrintClientCommandRequest): Observable<any> {
		return this.http.post(`${this._printServiceUrl}/api/v1/command/${clientId}`, request);
	}
}

interface PrintClientCommandRequest {
	commandType: PrintClientCommandType;
	commandParams: Record<string, string>;
}
