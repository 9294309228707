import { Component } from '@angular/core';
import { BaseCustomerSelectorComponent } from '@core/abstractions';
import { CustomerLightModel } from '@shared/models';
import { MetaDataService } from '@shared/services';
import { FilterService } from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
	selector: 'customer-selector',
	templateUrl: './customer-selector.component.html',
	styleUrls: ['./customer-selector.component.scss'],
})
export class CustomerSelectorComponent extends BaseCustomerSelectorComponent {
	constructor(
		filterService: FilterService,
		private metaDataService: MetaDataService,
	) {
		super(filterService);
	}

	public override getCustomer(): Observable<CustomerLightModel[]> {
		return this.metaDataService.getCustomers();
	}
}
