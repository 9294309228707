<div
	class="file-dropzone"
	(click)="triggerFileInput(fileInput)"
	(dragover)="onDragOver($event)"
	(dragleave)="onDragLeave($event)"
	(drop)="onDrop($event)"
	[class.dragging]="isDragging"
	[ngStyle]="{
		width: width + 'px',
		height: height + 'px'
	}"
>
	@if (!file) {
		<span class="display-center">{{ placeholder }}</span>
	} @else {
		@if (type === 'img') {
			<img src="" alt="" />
		} @else {
			<span class="display-center">File: {{ file.name }}</span>
		}
	}

	<input type="file" (change)="onFileSelected($event)" hidden #fileInput />
</div>
