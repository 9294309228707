import { BaseHeadersModel, IExcel, IHeaderOption, IStoredHeaderSettings } from '@core/models';
import { DocumentHeadersService } from '@core/services';
import { OrderType } from '@orders/enums';
import { IHeader } from '@shared/models';
import * as ExcelJS from 'exceljs';

export abstract class BaseXlsxImport {
	public selectedOrderType!: OrderType;
	public excelReading: boolean = false;
	public selectedWorkbook: ExcelJS.Workbook | null = null;
	public selectedFileName: string | null = null;
	public excelWarning: string | undefined = undefined;
	public hasColumnTitles: boolean = true;
	abstract defaultHeader: IHeader[];
	public headersList: IHeaderOption[] = [];
	abstract headersModel: BaseHeadersModel;
	public isMappingVisible: boolean = false;
	public excelErrors: string[] = [];

	constructor(private _documentHeadersService: DocumentHeadersService) {}

	public onExcelSelected(excel: IExcel) {
		this.excelReading = true;
		this.excelWarning = undefined;
		this.selectedFileName = excel.fileName;
		this.selectedWorkbook = excel.workbook;

		const storedHeaders = this.getHeaders();
		const rawHeaders = this._documentHeadersService.get(excel.workbook);
		this.hasColumnTitles = storedHeaders.hasColumnTitles;

		if (!storedHeaders.headers) {
			const mappedHeaders = this.mapWithDefaultHeaders(rawHeaders);
			this.setHeaderModel(mappedHeaders);
		} else {
			this.setHeaderModel(storedHeaders.headers);
			this.checkSelectedIndexes(rawHeaders);
		}

		const startChar = 'A'.charCodeAt(0);
		this.headersList = Object.keys(rawHeaders).map(key => {
			return {
				title: key,
				value: rawHeaders[key],
				letter: String.fromCharCode(startChar + rawHeaders[key] - 1),
			};
		});

		if (!this.isRequiredSelected()) {
			this.isMappingVisible = true;
		}

		this.excelReading = false;
	}

	private mapWithDefaultHeaders(rawHeaders: Record<string, number>): Record<string, number> {
		const headerDictionary = this.defaultHeader.reduce<Record<string, IHeader>>((acc, cur) => {
			acc[cur.displayValue] = cur;
			return acc;
		}, {});

		return Object.keys(rawHeaders).reduce<Record<string, number>>((acc, cur) => {
			const header = headerDictionary[cur];
			if (header) {
				acc[header.key] = rawHeaders[cur];
			}
			return acc;
		}, {});
	}

	private setHeaderModel(headers: Record<string, number>) {
		for (let key in headers) {
			this.headersModel[key] = headers[key];
		}
	}

	private checkSelectedIndexes(rawHeaders: Record<string, number>) {
		const optionValues = Object.values(rawHeaders);
		Object.keys(this.headersModel).forEach(key => {
			if (this.headersModel[key] && !optionValues.includes(this.headersModel[key]!)) {
				this.headersModel[key] = null;
				this.isMappingVisible = true;
			}
		});
	}

	public isRequiredSelected(): boolean {
		this.excelErrors = [];
		const requiredHeaders = this.defaultHeader.filter(_ => _.required);

		requiredHeaders.forEach(requiredHeader => {
			if (this.headersModel[requiredHeader.key] === null) {
				this.excelErrors.push(`${requiredHeader.displayValue} column is missing.`);
			}
		});

		return this.excelErrors.length == 0;
	}

	public showMapping() {
		this.isMappingVisible = !this.isMappingVisible;
	}

	public import() {
		if (!this.isRequiredSelected()) {
			this.isMappingVisible = true;
			return;
		}

		const headers = Object.keys(this.headersModel).reduce<Record<string, number>>((acc, key) => {
			if (this.headersModel[key] != null) {
				acc[key] = this.headersModel[key]!;
			}
			return acc;
		}, {});

		this.setHeaders({ headers: headers, hasColumnTitles: this.hasColumnTitles });

		this.readExcel(headers);
	}

	public finalizeReading() {
		this.excelReading = false;
		this.selectedFileName = null;
		this.selectedWorkbook = null;
	}

	public abstract readExcel(headers: Record<string, number>): void;

	protected abstract getHeaders(): IStoredHeaderSettings;
	protected abstract setHeaders(headers: IStoredHeaderSettings): void;
}
