<div class="control-input-container" [ngClass]="{ 'without-label': !label }">
	@if (label) {
		<label>{{ label }}</label>
	}
	@if (loading$ | async; as loading2) {
		<skeleton [leftBorder]="false"></skeleton>
	} @else {
		<div class="input-box">
			<p-autoComplete
				gaModelChangeEvent="Location filter changed"
				[group]="true"
				[(ngModel)]="selectedLocation"
				[suggestions]="filteredLocations"
				[showClear]="showClear"
				optionLabel="name"
				(onClear)="onClear()"
				(completeMethod)="filterGroupedLocations($event)"
				(onSelect)="locationKeyChange.emit($event.value.locationKey)"
				field="name"
				panelStyleClass="notranslate"
				[dropdown]="true"
				placeholder="Select Location"
				[disabled]="disabled"
				appendTo="body"
			>
				<ng-template let-group pTemplate="group">
					<div class="flex align-items-center">
						<span>{{ group.name }}</span>
					</div>
				</ng-template>
			</p-autoComplete>
		</div>
	}
</div>
