import { environment } from '@environments/environment';

export class PrinterImageUrlHelper {
	public static getImageUrl(name: string): string | undefined {
		if (name.toUpperCase().startsWith('ZD500R')) {
			return `${environment.printerImageBaseUrl}ZD500R.png`;
		}

		if (name.toUpperCase().startsWith('ZD621R')) {
			return `${environment.printerImageBaseUrl}ZD621R.png`;
		}

		if (name.toUpperCase().startsWith('ZT410R')) {
			return `${environment.printerImageBaseUrl}ZT410R.png`;
		}

		if (name.toUpperCase().startsWith('ZT411R')) {
			return `${environment.printerImageBaseUrl}ZT411R.png`;
		}

		if (name.toUpperCase().startsWith('R110XI4')) {
			return `${environment.printerImageBaseUrl}R110xi4.png`;
		}

		if (name.toUpperCase().startsWith('T820')) {
			return `${environment.printerImageBaseUrl}Pt-T820.png`;
		}

		if (name.toUpperCase().startsWith('T6304')) {
			return `${environment.printerImageBaseUrl}Pt-T6000.png`;
		}

		if (name.toUpperCase().startsWith('T6604')) {
			return `${environment.printerImageBaseUrl}Pt-T6000.png`;
		}

		//TODO: investigate model name for T4000
		// if (name.toUpperCase().startsWith('T4000')) {
		// 	return `${environment.printerImageBaseUrl}Pt-T4000.png`;
		// }

		return `${environment.printerImageBaseUrl}no_printer.png`;
	}

	public static getDefaultImage() {
		return `${environment.printerImageBaseUrl}no_printer.png`;
	}
}
