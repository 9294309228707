export enum LogLevel {
	Verbose = 'Trace',
	Debug = 'Debug',
	Information = 'Information',
	Warning = 'Warning',
	Error = 'Error',
	Fatal = 'Critical',
}

export enum PrintClientCommandType {
	Calibrate = 'Calibrate',
	DeletePrinter = 'DeletePrinter',
}

export enum PrintClientCommandParams {
	SerialNumber = 'SerialNumber',
	ClientId = 'ClientId',
}
