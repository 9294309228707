import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerLightViewModel } from '@shared/models';
import { MetaDataService } from '@shared/services';
import { TokenHelper } from '@shared/util';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, tap } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'customer-search',
	templateUrl: './customer-search.component.html',
	styleUrls: ['./customer-search.component.scss'],
})
export class CustomerSearchComponent implements OnInit {
	public customers: CustomerLightViewModel[] = [];
	public searchKeyword: string = '';
	public isLoading: boolean = true;

	@Output() addCustomerId: EventEmitter<number> = new EventEmitter();

	@Input() disabled: boolean = false;

	@Input() selectedIds: number[] = [];

	constructor(
		private metaDataService: MetaDataService,
		private _oidcSecurityService: OidcSecurityService,
	) {}

	ngOnInit() {
		combineLatest({
			customers: this.metaDataService.getCustomers(),
			token: this._oidcSecurityService.checkAuth(),
		})
			.pipe(
				tap(resp => {
					if (TokenHelper.hasAdminRole(resp.token.userData)) {
						this.customers = resp.customers;
					} else {
						const managerVendorIds = TokenHelper.getMangerVendorIds(resp.token.userData);
						this.customers = resp.customers.filter(_ => managerVendorIds.includes(_.id));
					}
					if (this.customers.length == 1) {
						this.disabled = true;
						this.onCustomerSelect(this.customers[0]);
					}
					this.isLoading = false;
				}),
				untilDestroyed(this),
			)
			.subscribe();
	}

	public onCustomerSelect(customer: CustomerLightViewModel) {
		this.addCustomerId.emit(customer.id);
	}
}
