import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { IExcel } from '@core/models';
import * as ExcelJS from 'exceljs';
import { FileSelectEvent } from 'primeng/fileupload';
import { from, fromEvent, of, switchMap } from 'rxjs';
import { Papa } from 'ngx-papaparse';

@Directive({
	selector: '[excelReader]',
})
export class ExcelReaderDirective {
	@Output() dataChanged = new EventEmitter<IExcel>();
	private _reader: FileReader;
	private _fileName!: string;
	private _fileType!: string;

	constructor(
		private el: ElementRef<HTMLInputElement>,
		private papa: Papa,
	) {
		this._reader = new FileReader();

		fromEvent(this._reader, 'loadend')
			.pipe(
				switchMap(_ => {
					const reader = _.target as FileReader;
					const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();

					// TODO: NikitaV - Create factory and parsers of each possible parsers
					if (this._fileType == 'text/csv') {
						const fileReaderResult: string = new TextDecoder().decode(reader.result as ArrayBuffer);
						const worksheet = workbook.addWorksheet('CSV Data');

						this.papa.parse(fileReaderResult, {
							skipEmptyLines: true,
							step: result => {
								worksheet.addRow(result.data);
							},
						});

						return of(workbook);
					}

					return from(workbook.xlsx.load(reader.result as ArrayBuffer));
				}),
			)
			.subscribe(_ => {
				el.nativeElement.value = '';
				this.dataChanged.emit({ workbook: _, fileName: this._fileName });
			});
	}

	@HostListener('onSelect', ['$event']) onFileSelected(event: FileSelectEvent) {
		if (event.currentFiles.length > 0) {
			this._fileName = event.currentFiles[0].name;
			this._fileType = event.currentFiles[0].type;
			this._reader.readAsArrayBuffer(event.currentFiles[0]);
		}
	}
}
