<div class="add-input">
	<div class="control-input-container without-label" [ngClass]="{ 'has-error-message': errorMessage }">
		<div class="input-box">
			<input
				gaEvent="Changed company prefix input"
				gaBind="change"
				[(ngModel)]="prefixValue"
				class="control-input"
				(keyup)="validate()"
			/>
		</div>

		@if (errorMessage) {
			<div class="pos-bottom">
				<p-message severity="danger" summary="Error" [text]="errorMessage"></p-message>
			</div>
		}
	</div>

	<app-button
		gaEvent="Add company prefix"
		[variant]="buttonVariantEnum.Primary"
		icon="mdi mdi-plus"
		[isDisabled]="!isValid"
		(click)="add()"
	/>
</div>

@if (companyPrefixes$ | async; as companyPrefixes) {
	<ul class="added-items-list">
		@for (companyPrefix of companyPrefixes; track companyPrefix) {
			<li>
				<span>{{ companyPrefix.value }}</span>
				<i gaEvent="Delete company prefix" class="mdi mdi-close close-icon" (click)="delete(companyPrefix)"></i>
			</li>
		}
	</ul>
}
