import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
	AuthorizedLayoutComponent,
	NavMenuComponent,
	NotFoundComponent,
	PrintersSidebarComponent,
	VendorSelectorPopupComponent,
} from '@layout/components';
import SharedModule from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';

@NgModule({
	declarations: [
		AuthorizedLayoutComponent,
		NavMenuComponent,
		VendorSelectorPopupComponent,
		NotFoundComponent,
		PrintersSidebarComponent,
	],
	imports: [CommonModule, DynamicDialogModule, SharedModule, MenuModule, BreadcrumbModule, CoreModule, SidebarModule],
	exports: [AuthorizedLayoutComponent],
})
export default class LayoutModule {}
