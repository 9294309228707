import { Injectable } from '@angular/core';
import { BaseLocationName } from '@core/abstractions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationLightModel } from '@shared/models';
import { MetaDataService } from '@shared/services';
import { RecordHelper } from '@shared/util';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class LocationNameService extends BaseLocationName {
	constructor(private metaDataService: MetaDataService) {
		super();
		this.metaDataService
			.getLocations()
			.pipe(untilDestroyed(this))
			.subscribe((locations: LocationLightModel[]) => {
				this._locationsSourceDictionary = RecordHelper.toRecord(locations, 'locationKey');
				this._locationsSource = locations;
			});
	}
}
