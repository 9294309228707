export enum InventoryOrderField {
	Id = 'Id',
	Title = 'Title',
	Sku = 'SKU',
	Brand = 'Brand',
	Variants = 'Variants',
	Tags = 'Tags',
	VerifiedDate = 'Verified date',
	AbsentDate = 'Absent date',
	ArchivedDate = 'Archived date',
	Comments = 'Comments',
	Isn = 'Isn',
	Price = 'Price',
	Count = 'Count',
	LocationName = 'LocationName',
}
