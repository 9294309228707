import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
})
export class ButtonComponent {
	public buttonVariantEnum = ButtonVariant;

	@Input() icon: string | undefined;
	@Input() customClass: string | undefined;
	@Input() isDisabled: boolean | null = false;
	@Input() text: string | undefined;
	@Input() tooltip: string | undefined;
	@Input() onRouterLink: string | any[] | null | undefined;
	@Input() tooltipAlign: TooltipAlign = TooltipAlign.Top;
	@Input({ required: true }) variant!: ButtonVariant;
	@Input() onClick: (() => void) | undefined;

	protected handleClick() {
		if (this.onClick) {
			this.onClick();
		}
	}
}

export enum TooltipAlign {
	Top = 'top',
	Right = 'right',
	Left = 'left',
	Bottom = 'bottom',
}

export enum ButtonVariant {
	Primary = 'primary',
	Light = 'light',
	Danger = 'danger',
}

export interface ButtonModel {
	variant: ButtonVariant;
	isDisabled?: boolean | null;
	customClass?: string;
	gaEvent?: string;
	text?: string | undefined;
	icon?: string | undefined;
	onClick?: () => void;
	routerLink?: string | any[] | null | undefined;
	tooltip?: string | undefined;
	tooltipAlign?: TooltipAlign;
}
