<p-overlayPanel #productsDD styleClass="custom-position">
	<ng-template pTemplate="content">
		@if (!isLoading) {
			<div class="scroller">
				@if (products.length !== 0) {
					<div class="notranslate">
						@for (item of products; track item) {
							<div gaEvent="Product added from search" class="list-item" (click)="onProductSelect(item)">
								{{ item.title }} | {{ item.sku }}
								@if (item.itemsPerTag > 1) {
									({{ item.itemsPerTag }})
								}
							</div>
						}
					</div>
				} @else {
					<span> Nothing Found </span>
				}
			</div>
		} @else {
			<div class="skeleton-rows">
				<skeleton [height]="18"></skeleton>
				<skeleton [height]="18"></skeleton>
				<skeleton [height]="18"></skeleton>
			</div>
		}
	</ng-template>
</p-overlayPanel>

<div class="overlay-custom-position-wrapper">
	<div class="control-input-container without-label">
		<div class="input-box input-icon-absolute-right">
			<i class="mdi mdi-magnify static-icon"></i>

			<input
				placeholder="Type to search products..."
				class="control-input without-label"
				type="text"
				(click)="productsDD.show($event, overlayTarget)"
				(keyup)="onSearch($event)"
				[disabled]="disabled"
				pInputText
			/>
		</div>
	</div>
	<div class="overlay-target" #overlayTarget></div>
</div>
