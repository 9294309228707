import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor() {}

	public get<T>(key: string): T | undefined {
		const value = localStorage.getItem(key);

		if (value) {
			const obj: T = JSON.parse(value);
			return obj;
		}

		return undefined;
	}

	public set<T>(key: string, value: T) {
		localStorage.setItem(key, JSON.stringify(value));
	}
}
