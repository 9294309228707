import { Component } from '@angular/core';
import { BaseCustomerSelectorComponent } from '@core/abstractions';
import { CustomerLightModel } from '@shared/models';
import { MetaDataService } from '@shared/services';
import { TokenHelper } from '@shared/util/token-helper';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FilterService } from 'primeng/api';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
	selector: 'manager-customer-selector',
	templateUrl: './manager-customer-selector.component.html',
	styleUrls: ['./manager-customer-selector.component.scss'],
})
export class ManagerCustomerSelectorComponent extends BaseCustomerSelectorComponent {
	constructor(
		filterService: FilterService,
		private metaDataService: MetaDataService,
		private _oidcSecurityService: OidcSecurityService,
	) {
		super(filterService);
	}

	public override getCustomer(): Observable<CustomerLightModel[]> {
		return combineLatest({
			customers: this.metaDataService.getCustomers(),
			token: this._oidcSecurityService.checkAuth(),
		}).pipe(
			map(resp => {
				const managerVendorIds = TokenHelper.getMangerVendorIds(resp.token.userData);
				return resp.customers.filter(_ => managerVendorIds.includes(_.id));
			}),
		);
	}
}
