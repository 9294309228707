import { Component, Input } from '@angular/core';
import { UserContextService } from '@shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'vendor-selector-popup',
	templateUrl: './vendor-selector-popup.component.html',
	styleUrls: ['./vendor-selector-popup.component.scss'],
})
export class VendorSelectorPopupComponent {
	constructor(
		public vendorService: UserContextService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
	) {
		this.selectedVendorId = config.data.vendorId;
	}

	public testVendorId = 66;
	@Input() public selectedVendorId: number | undefined = this.testVendorId;

	public vendorChanged() {
		if (!this.selectedVendorId) {
			return;
		}

		this.ref.close(this.selectedVendorId);
	}
}
