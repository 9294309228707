import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerLightModel } from '@shared/models';
import { IVendorModel } from '@shared/models/vendor';
import { CustomerService, UserLocalStorageService } from '@shared/services';
import { TokenHelper } from '@shared/util/token-helper';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, of, shareReplay, switchMap } from 'rxjs';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class UserContextService {
	private _vendorsSource$: Observable<CustomerLightModel[]>;
	private readonly _vendor$: Observable<IVendorModel>;
	private readonly _userId$: Observable<string>;
	private _counter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private _onVendorChange = new Subject<number>();

	constructor(
		private _customerService: CustomerService,
		private _localStorageService: UserLocalStorageService,
		private _oidcSecurityService: OidcSecurityService,
	) {
		this._vendorsSource$ = this._customerService.getVendors();

		const userData$ = this._oidcSecurityService.checkAuth().pipe(
			filter(_ => _.userData != null),
			map(_ => {
				if (!_.userData.sub) {
					throw new Error('UserId not found.');
				}
				return _.userData;
			}),
			shareReplay(),
		);

		this._userId$ = userData$.pipe(
			map(_ => {
				return _.sub;
			}),
		);

		this._vendor$ = combineLatest({
			counter: this._counter$,
			userData: userData$,
			vendors: this._vendorsSource$,
		}).pipe(
			filter(_ => _.vendors.length > 0),
			map(result => {
				const userId = result.userData.sub;
				let storedVendorId: number | undefined = this._localStorageService.get(userId);
				const isAdmin = TokenHelper.hasAdminRole(result.userData);
				const availableVendorIds = TokenHelper.getAvailableVendorIds(result.userData);

				if (storedVendorId) {
					if (
						!isAdmin &&
						availableVendorIds.length > 0 &&
						!availableVendorIds.some(_ => _ == storedVendorId)
					) {
						storedVendorId = availableVendorIds[0];
						this._localStorageService.set(userId, storedVendorId);
					}

					const vendor = result.vendors.find(_ => _.id === storedVendorId);

					if (vendor === undefined) {
						//TODO: in this case we should redirect to forbidden page
						throw new Error('Vendor not found');
					} else {
						return vendor;
					}
				} else {
					const vendor = result.vendors[0];

					this._localStorageService.set(userId, vendor.id);

					return vendor;
				}
			}),
			shareReplay(),
		);

		this._onVendorChange
			.pipe(
				switchMap(vendorId => {
					return combineLatest({
						userId: this._userId$,
						vendorId: of(vendorId),
					});
				}),
				map(resp => {
					this._localStorageService.set(resp.userId, resp.vendorId);
					this.updateVendor();
				}),
				untilDestroyed(this),
			)
			.subscribe();
	}

	public getVendor(): Observable<IVendorModel> {
		return this._vendor$;
	}

	public getUserId(): Observable<string> {
		return this._userId$;
	}

	public setVendor(vendorId: number) {
		this._onVendorChange.next(vendorId);
	}

	private updateVendor() {
		let counter = this._counter$.value;
		this._counter$.next(++counter);
	}
}
