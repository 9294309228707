import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				postLoginRoute: '',
				// Redirect if response is 403:
				// https://nice-hill-002425310.azurestaticapps.net/docs/documentation/configuration#:~:text=The%20Angular%20route%20to%20redirect%20the%20client%20to%20when%20the%20server%20returns%20an%20HTTP%20403%20response.
				forbiddenRoute: '/authentication/forbidden',
				unauthorizedRoute: '/authentication/unauthorized',
				historyCleanupOff: false,
				authority: environment.identityBaseAddress,
				redirectUrl: `${window.location.origin}/authentication/signin-callback`,
				postLogoutRedirectUri: `${window.location.origin}/authentication/signout-callback`,
				clientId: 'portal.client.spa.pkce',
				scope: 'offline_access openid profile email identity.api printing',
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				secureRoutes: [
					environment.portalBaseAddress,
					environment.printServiceBaseAddress,
					environment.emailService.baseAddress,
					environment.identityBaseAddress,
				],
				logLevel: LogLevel.Debug,
			},
		}),
	],
	exports: [AuthModule],
})
export class AuthConfigModule {}
