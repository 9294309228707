export enum PrintJobOrderBy {
	Id = 'Id',
	ReferenceName = 'ReferenceName',
	Created = 'Created',
	Verified = 'Verified',
	JobNumber = 'JobNumber',
	UserName = 'UserName',
	LabelName = 'LabelName',
	LocationName = 'LocationName',
	PrinterName = 'PrinterName',
	Started = 'Started',
	Status = 'Status',
	DocumentsCount = 'DocumentsCount',
}
