import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerPermissionViewModel, LocationTypePermissionViewModel } from '@core/models';
import { PermissionService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, tap } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'setup-permissions',
	templateUrl: './setup-permissions.component.html',
	styleUrls: ['./setup-permissions.component.scss'],
})
export class SetupPermissionsComponent {
	public locationKeyword: string = '';
	public customerKeyword: string = '';
	public selectedCustomer$: BehaviorSubject<CustomerPermissionViewModel | null> =
		new BehaviorSubject<CustomerPermissionViewModel | null>(null);

	public permissions$: BehaviorSubject<CustomerPermissionViewModel[]> = new BehaviorSubject<
		CustomerPermissionViewModel[]
	>([]);

	public isLoading: boolean = false;

	@Input() set permissions(val: CustomerPermissionViewModel[]) {
		if (val.length > 0) {
			this.permissions$.next(val);
			if (!this.selectedCustomer$.value) {
				this.selectedCustomer$.next(val[0]);
			}
		}
	}

	@Output() permissionsChange: EventEmitter<CustomerPermissionViewModel[]> = new EventEmitter<
		CustomerPermissionViewModel[]
	>();

	constructor(private _permissionService: PermissionService) {}

	public onCustomerChange(customer: CustomerPermissionViewModel) {
		if (customer.isChecked) {
			customer.isChanged = true;
		} else {
			customer.isChanged = false;
		}
	}

	public onLocationChange(location: LocationTypePermissionViewModel, customer: CustomerPermissionViewModel) {
		if (location.isChecked || location.locations?.some(_ => _.isChecked)) {
			location.isChanged = true;
			customer.isChanged = true;
		} else {
			location.isChanged = false;
			customer.isChanged = false;
		}
	}

	public addCustomer(customerId: number) {
		this._permissionService
			.get(customerId)
			.pipe(
				tap(_ => (this.isLoading = true)),
				untilDestroyed(this),
				//TODO: add catch
			)
			.subscribe(_ => {
				const permissions = this.permissions$.value;

				if (permissions.some(p => p.id == _.id)) {
					return;
				}

				permissions.push(_);
				this.isLoading = false;
				this.permissionsChange.emit([...permissions]);
			});
	}

	public getSelectedCustomerIds(): number[] {
		return this.permissions$.value.map(_ => {
			return _.id;
		});
	}

	public removeCustomer(customer: CustomerPermissionViewModel) {
		let permissions = this.permissions$.value;
		const index = permissions.indexOf(customer);
		permissions.splice(index, 1);
		if (this.selectedCustomer$.value?.id == customer.id) {
			this.selectedCustomer$.next(permissions.length > 0 ? permissions[0] : null);
		}
	}
}
