<div
	class="input-component-wrapper {{ containerClass }}"
	[ngClass]="{ 'has-error-message': error }"
	[ngStyle]="{
		maxWidth: maxWidth + 'px',
		minWidth: minWidth + 'px',
	}"
>
	<div class="control-input-container without-label">
		@if (readonly) {
			<div class="input-box readonly notranslate">
				<span>{{ value }} </span>
				@if (error) {
					<div [ngClass]="errorPosition">
						<p-message severity="danger" summary="Error" [text]="error"></p-message>
					</div>
				}
			</div>
		} @else {
			<div stop-redirect class="input-box notranslate">
				<input
					gaEvent="Changed {{ gaName }} input"
					gaBind="change"
					[minValue]="minNumValue"
					class="control-input"
					[type]="type"
					[id]="id"
					[placeholder]="placeholder"
					[(ngModel)]="value"
					(change)="valueChanged()"
					(keyup)="keyup()"
				/>
			</div>
		}
	</div>

	@if (error && !readonly) {
		<div [ngClass]="errorPosition">
			<p-message severity="danger" summary="Error" [text]="error"></p-message>
		</div>
	}
</div>
