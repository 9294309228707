
export enum PrintingDocumentStatus {
	Ready = 'Ready', //ready for print ASAP
	InProgress = 'InProgress', //printing right now
	Pending = 'Pending', //paused for some reasons
	Printed = 'Printed', //has printed
	Failed = 'Failed', //fail during print
	Completed = 'Completed', //printing process completed
	Canceled = 'Canceled', //printing process canceled
	ManualCanceled = 'ManualCanceled', //printing process canceled by user
}
export const PrintingDocumentStatusDisplayValue: Record<string, string> = {
	Ready: 'Ready',
	InProgress: 'In Progress',
	Pending: 'Pending',
	Printed: 'Printed',
	Failed: 'Failed',
	Completed: 'Completed',
	Canceled: 'Canceled',
	Postponed: 'Postponed',
};
