import { Injectable } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, filter, map, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageKeyService {
	private _userId$: Observable<string>;
	private routeKey: string;
	constructor(_activeRoute: ActivatedRoute, _oidcSecurityService: OidcSecurityService) {
		this._userId$ = _oidcSecurityService.checkAuth().pipe(
			filter(_ => _.userData != null),
			map(_ => {
				if (!_.userData.sub) {
					throw new Error('UserId not found.');
				}
				return _.userData.sub;
			}),
		);

		this.routeKey = _activeRoute.snapshot.pathFromRoot
			.filter(_ => _.routeConfig != null)
			.map(_ => {
				return _.routeConfig?.path;
			})
			.join('_');
	}

	public fromCurrentRoute(name: string): Observable<string> {
		return this._userId$.pipe(
			switchMap(userId => {
				return of([userId, this.routeKey, name].join('_'));
			}),
			shareReplay()
		);
	}

	public fromRoot(name: string): Observable<string> {
		return this._userId$.pipe(
			switchMap(userId => {
				return of([userId, name].join('_'));
			}),
			shareReplay()
		);
	}
}
