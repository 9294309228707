import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerLightModel } from '@shared/models';
import { FilterService } from 'primeng/api';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({ template: '' })
export abstract class BaseCustomerSelectorComponent implements OnInit {
	public filteredCustomers!: CustomerLightModel[];
	public selectedCustomer: CustomerLightModel | undefined = undefined;
	protected sourceCustomers: CustomerLightModel[] = [];
	private _customerId: number | undefined = undefined;
	public loading: boolean = true;

	get count(): number {
		return this.sourceCustomers.length;
	}

	@Input() get selectedId(): number | undefined {
		return this._customerId;
	}

	set selectedId(val: number | undefined) {
		if (this._customerId == val) {
			return;
		}

		this._customerId = val;
		this.selectedCustomer = this.sourceCustomers.find(_ => _.id == this.selectedId);
	}

	@Output() selectedIdChange: EventEmitter<number> = new EventEmitter<number>();

	@Input() readonly: boolean = false;

	@Input() showClear: boolean = false;

	@Input() label?: string;

	constructor(private _filterService: FilterService) {}

	public ngOnInit(): void {
		this.getCustomer()
			.pipe(untilDestroyed(this))
			.subscribe(_ => {
				this.sourceCustomers = _;
				if (this.sourceCustomers.length > 0) {
					this.selectedCustomer = this.sourceCustomers.find(_ => _.id == this.selectedId);
				}
				this.loading = false;
			});
	}

	public abstract getCustomer(): Observable<CustomerLightModel[]>;

	public onCustomerSelect(vendor: CustomerLightModel) {
		this.selectedIdChange.emit(vendor.id);
	}

	public onClear() {
		this.selectedIdChange.emit(undefined);
	}

	public filterCustomers(event: { query: string }) {
		let query = event.query;
		this.filteredCustomers = this._filterService.filter(
			this.sourceCustomers,
			['name', 'abbreviation'],
			query,
			'contains',
		);
	}

	public updateCustomers(customersSource: CustomerLightModel[]) {
		this.sourceCustomers = customersSource;
	}
}
