export class ProductViewModel {
	public id: number;
	public internalId: number;
	public productId: number;
	public name: string;
	public title: string;
	public sku: string;
	public itemsPerTag: number;
	public count: number;
	public destinationVerified: number;
	public originVerified: number;
	public bornDate?: Date;
	public expirationDate?: Date;
	public sellBy?: Date;
	public lotNumber?: string;
	public cost?: number;
	public serialNumber?: string;
	public hasErrors: boolean = false;
	public errors: IProductValidation;
	public isActive: boolean = false;

	constructor(
		productId: number,
		name: string,
		title: string,
		sku: string,
		itemsPerTag: number,
		count: number = 1,
		id: number = 0,
		destinationVerified: number = 0,
		originVerified: number = 0,
		bornDate?: Date,
		expirationDate?: Date,
		sellBy?: Date,
		lotNumber?: string,
		cost?: number,
		serialNumber?: string,
	) {
		this.productId = productId;
		this.name = name;
		this.title = title;
		this.sku = sku;
		this.itemsPerTag = itemsPerTag;
		this.count = count;
		this.errors = {};
		this.id = id;
		this.internalId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
		this.bornDate = bornDate;
		this.expirationDate = expirationDate;
		this.sellBy = sellBy;
		this.lotNumber = lotNumber;
		this.cost = cost;
		this.serialNumber = serialNumber;
		this.destinationVerified = destinationVerified;
		this.originVerified = originVerified;
	}

	static GetUnknown() {
		return new ProductViewModel(0, 'Unknown', 'Unknown', 'Unknown', 0, 0);
	}
}

export interface IProductValidation {
	lotNumber?: string | null;
	cost?: string | null;
	serialNumber?: string | null;
	sku?: string | null;
	bornDate?: string | null;
	expirationDate?: string | null;
	sellBy?: string | null;
	count?: string | null;
}
