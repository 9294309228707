<div class="search-wrapper">
	<div class="search-container">
		<!-- (keyup.enter)="searchBtn.click()"not working with custom button component -->
		<input
			[(ngModel)]="searchKeyword"
			type="text"
			placeholder="Search..."
			class="control-input"
			(keyup.enter)="emitValue()"
		/>
		<app-button
			#searchBtn
			gaEvent="Search something"
			[variant]="buttonVariantEnum.Primary"
			icon="mdi mdi-magnify"
			(click)="emitValue()"
		/>
	</div>
	<ng-content></ng-content>
</div>
