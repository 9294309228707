import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OffsetCollection, OffsetPagination } from '@shared/models';
import { IProductSearchModel } from '@shared/models/products';
import { IVendorModel } from '@shared/models/vendor';
import { ChunkService } from '@shared/services/chunk.service';
import { UserContextService } from '@shared/services/user-context.service';
import { Observable, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	private portalUrl: string;

	constructor(
		private _vendorService: UserContextService,
		private _chunkService: ChunkService,
	) {
		this.portalUrl = environment.portalBaseAddress;
	}

	public Search(filter: IProductFilter): Observable<OffsetCollection<IProductSearchModel>> {
		const url = `${this.portalUrl}/api/v1/products/search`;
		return this._vendorService.getVendor().pipe(
			switchMap((vendor: IVendorModel) => {
				let params: IProductFilterParams = {
					vendorId: vendor.id,
				};

				if (filter.deleted != undefined) {
					params.deleted = filter.deleted;
				}

				let pager: OffsetPagination = OffsetPagination.Default();

				if (filter.keyword != undefined) {
					params.keyword = filter.keyword;
				}

				if (filter.skus != undefined) {
					params.skus = filter.skus;
					pager = new OffsetPagination(0, filter.skus.length);
				}

				if (filter.ids != undefined) {
					params.ids = filter.ids;
					pager = new OffsetPagination(0, filter.ids.length);
				}

				return this._chunkService.get<IProductSearchModel, IProductFilterParams>(pager, params, url);
			}),
		);
	}
}

interface IProductFilterParams {
	vendorId: number;
	deleted?: boolean;
	skus?: string[];
	keyword?: string | null;
	ids?: number[];
}

export interface IProductFilter {
	deleted?: boolean;
	ids?: number[];
	skus?: string[];
	keyword?: string | null;
}
