import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ButtonVariant } from '@shared/components';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'company-prefix-input',
	templateUrl: './company-prefix-input.component.html',
	styleUrls: ['./company-prefix-input.component.scss'],
})
export class CompanyPrefixInputComponent {
	public companyPrefixes$ = new BehaviorSubject<ICompanyPrefixInputModel[]>([]);
	public isValid: boolean = false;
	public errorMessage?: string;
	public prefixValue?: string;
	private readonly _companyPrefixPattern: RegExp = new RegExp('^\\d{6,11}$');
	public buttonVariantEnum = ButtonVariant;

	@Input()
	set prefixes(val: ICompanyPrefixInputModel[] | null) {
		if (val) {
			this.companyPrefixes$.next([...val]);
		}
	}

	@Output() prefixesChange = new EventEmitter<ICompanyPrefixInputModel[]>();
	@Output() prefixDelete = new EventEmitter<ICompanyPrefixInputModel>();

	public delete(prefix: ICompanyPrefixInputModel) {
		this.prefixDelete.emit(prefix);
	}

	public validate() {
		this.isValid = false;
		this.errorMessage = undefined;
		const a = this.companyPrefixes$.value.find(_ => _.value == this.prefixValue);
		if (this.prefixValue) {
			if (a) {
				this.errorMessage = 'The prefix must be unique.';
				return;
			}
			if (!this._companyPrefixPattern.test(this.prefixValue)) {
				this.errorMessage = 'The prefix must contain only numbers and be between 6 and 11 in length.';
				return;
			}
		}
		this.isValid = true;
	}

	public add() {
		const prefixes = this.companyPrefixes$.value;
		this.validate();
		if (this.isValid) {
			const newPrefix = {
				id: 0,
				value: this.prefixValue!,
			};
			this.prefixesChange.emit([newPrefix, ...prefixes]);
		}
		this.prefixValue = '';
		this.isValid = false;
	}
}

export interface ICompanyPrefixInputModel {
	id: number;
	value: string;
	vendorId?: number;
}
