import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppMessageService, PrintStationCommandService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IPrinterViewModel } from '@printing/models';
import { PortalPrintingService } from '@printing/services';
import { ConfirmMessagePosition, Dpi, State } from '@shared/enums';
import {
	NavigationService,
	PortalStationNotificationService,
	PrinterNotificationService,
	PrintStationNotificationService,
	UserContextService,
} from '@shared/services';
import { PrinterImageUrlHelper } from '@shared/util';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, exhaustMap, finalize, map, Subject, switchMap, tap } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'printers-sidebar',
	templateUrl: './printers-sidebar.component.html',
	styleUrl: './printers-sidebar.component.scss',
})
export class PrintersSidebarComponent implements OnInit, OnDestroy {
	@Input() isVisible: boolean = false;
	@Output() isVisibleChange = new EventEmitter();

	public isCalibrateConfirmVisible: boolean = false;
	public confirmMessagePositionEnum = ConfirmMessagePosition;
	public isSpinnerShown: boolean = false;

	public printerStateEnum = State;
	public printers$: BehaviorSubject<IUserPrinterViewModel[]> = new BehaviorSubject<IUserPrinterViewModel[]>([]);

	private _onCalibrate$: Subject<IUserPrinterViewModel> = new Subject();
	private _onUpdate$: Subject<void> = new Subject<void>();

	constructor(
		public navigationService: NavigationService,
		private printingService: PortalPrintingService,
		private _messageService: AppMessageService,
		userContextService: UserContextService,
		private _printStationNotification: PrintStationNotificationService,
		private _printerNotification: PrinterNotificationService,
		private _commandService: PrintStationCommandService,
		private _portalStationNotificationService: PortalStationNotificationService,
	) {
		combineLatest({
			vendor: userContextService.getVendor(),
			onUpdate: this._onUpdate$,
		})
			.pipe(
				switchMap(response => {
					return this.printingService.getAllPrinters(response.vendor.id).pipe(
						map(printers => {
							var orderedPrinters = _.orderBy(
								printers,
								['status', 'locationKey', 'locationName', 'displayName'],
								['desc'],
							);
							return orderedPrinters.map(_ => new IUserPrinterViewModel(_));
						}),
					);
				}),
				untilDestroyed(this),
			)
			.subscribe(this.printers$);
		this._onUpdate$.next();

		this._onCalibrate$
			.pipe(
				tap(_ => (this.isSpinnerShown = true)),
				exhaustMap(printer => {
					return this._commandService
						.calibrate(printer.clientId, printer.serialNumber)
						.pipe(finalize(() => (this.isSpinnerShown = false)));
				}),
			)
			.subscribe(() => {
				this.onHide();

				this._messageService.warn(
					'Do not turn printer power off! Do not start printing!',
					false,
					'Printer is calibrating. It may take up to 5 minutes.',
				);
			});
	}

	public ngOnInit(): void {
		this._printStationNotification.addStationStateChangedListener(this.onStationStateChanged.bind(this));
		this._printerNotification.addPrinterStateChangedListener(this.onPrinterStateChanged.bind(this));
		this._printerNotification.addPrinterRegisteredListener(this.onPrinterRegistered.bind(this));
		this._printerNotification.addPrinterDeletedListener(this.onPrinterDeleted.bind(this));
		this._portalStationNotificationService.addStationLinkedListener(this.onStationLinked.bind(this));
		this._portalStationNotificationService.addStationRemovedListener(this.onStationRemoved.bind(this));
		this._printStationNotification.startConnection();
		this._printerNotification.startConnection();
		this._portalStationNotificationService.startConnection();
	}

	public ngOnDestroy(): void {
		this._printStationNotification.removeStateChangedListener();
		this._printerNotification.removeStateChangedListener();
		this._printerNotification.removeRegisteredListener();
		this._printerNotification.removeDeletedListener();
		this._portalStationNotificationService.removeLinkedListener();
		this._portalStationNotificationService.removeRemovedListener();
	}

	public onHide() {
		this.isVisibleChange.emit((this.isVisible = false));
	}

	public calibrate(printer: IUserPrinterViewModel) {
		this._onCalibrate$.next(printer);
	}

	public showCalibrateConfirm() {
		this.isCalibrateConfirmVisible = true;
	}

	public pausePrintJobs() {
		return;
	}

	private onPrinterStateChanged(clientId: string, state: State, serialNumber?: string, issueDescription?: string) {
		let printer: IUserPrinterViewModel | undefined = undefined;
		const printers = this.printers$.value.filter(s => s.clientId == clientId);

		if (serialNumber) {
			printer = printers.find(s => s.serialNumber == serialNumber);
		}

		if (printer) {
			printer.status = state;
			printer.issueDescription = issueDescription;
		} else {
			printers.forEach(printer => {
				printer.status = state;
				printer.issueDescription = issueDescription;
			});
		}
	}

	private onStationStateChanged(clientId: string, state: State) {
		const printers = this.printers$.value.filter(s => s.clientId == clientId);
		printers.forEach(_ => {
			_.printStationStatus = state;
		});
	}
	//TODO: create one service with R signal for available printers for all pages
	private onStationLinked(clientId: string) {
		this._onUpdate$.next();
	}

	private onPrinterRegistered(clientId: string, serialNumber: string) {
		this._onUpdate$.next();
	}

	private onStationRemoved(clientId: string) {
		const printers = this.printers$.value.filter(s => s.clientId != clientId);
		this.printers$.next([...printers]);
	}

	private onPrinterDeleted(clientId: string, serialNumber: string) {
		const printers = this.printers$.value.filter(s => !(s.clientId == clientId && s.serialNumber == serialNumber));
		this.printers$.next([...printers]);
	}
}

class IUserPrinterViewModel {
	public id: number;
	public name: string;
	public serialNumber: string;
	public status: State;
	public displayName: string;
	public dpi: Dpi;
	public clientId: string;
	public locationName: string;
	public issueDescription?: string;

	private printStationName: string;
	public printStationId: number;
	public printStationStatus: State;
	private locationType: string;
	private locationKey: string;

	public imgUrl?: string;

	constructor(printer: IPrinterViewModel) {
		this.id = printer.id;
		this.name = printer.name;
		this.serialNumber = printer.serialNumber;
		this.status = printer.status;
		this.displayName = printer.displayName;
		this.dpi = printer.dpi;
		this.clientId = printer.clientId;
		this.locationName = printer.locationName;
		this.printStationName = printer.printStationName;
		this.printStationId = printer.printStationId;
		this.printStationStatus = printer.printStationStatus;
		this.locationType = printer.locationType;
		this.locationKey = printer.locationKey;
		this.imgUrl = PrinterImageUrlHelper.getImageUrl(printer.name);
		this.issueDescription = printer.issueDescription;
	}
}
