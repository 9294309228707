<div class="control-input-container" [ngClass]="{ 'without-label': !label }">
	@if (label) {
		<label>{{ label }}</label>
	}
	@if (loading) {
		<skeleton [maxWidth]="400"></skeleton>
	} @else {
		@if (readonly) {
			<div class="input-box notranslate">
				<span class="readonly-input">{{ selectedCustomer?.name }}</span>
			</div>
		} @else {
			<div class="input-box">
				<p-autoComplete
					gaModelChangeEvent="Customer selector changed"
					[(ngModel)]="selectedCustomer"
					[suggestions]="filteredCustomers"
					[showClear]="showClear"
					(completeMethod)="filterCustomers($event)"
					(onSelect)="onCustomerSelect($event.value)"
					panelStyleClass="notranslate"
					appendTo="body"
					field="name"
					placeholder="Select Customer"
					[dropdown]="true"
					[forceSelection]="true"
					(onClear)="onClear()"
				>
				</p-autoComplete>
			</div>
		}
	}
</div>
