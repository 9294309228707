import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerLightModel } from '@shared/models';
import { MetaDataService } from '@shared/services';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class CustomerNameService {
	private _locationsSource: Record<number, CustomerLightModel> = {};

	constructor(private metaDataService: MetaDataService) {
		this.metaDataService
			.getCustomers()
			.pipe(untilDestroyed(this))
			.subscribe((_: CustomerLightModel[]) => {
				this._locationsSource = _.reduce<Record<number, CustomerLightModel>>((previous, current) => {
					previous[current.id] = current;
					return previous;
				}, {});
			});
	}

	public findNameById(id: number): string {
		if (this._locationsSource[id]) {
			return this._locationsSource[id].name;
		}
		return 'N/A';
	}

	public isKnow(id: number): boolean {
		if (this._locationsSource[id]) {
			return true;
		}
		return false;
	}
}
