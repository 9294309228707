<div class="gallery-wrapper">
	<p-galleria
		[value]="images"
		[thumbnailsPosition]="position"
		[numVisible]="999"
		[circular]="true"
		[(activeIndex)]="activeIndex"
	>
		<ng-template pTemplate="item" let-item>
			<div class="selected-img-control-container">
				<div class="selected-img-box">
					<img id="myimage" [src]="item.itemImageSrc" />
				</div>
				<app-button
					[variant]="buttonVariantEnum.Light"
					icon="mdi mdi-chevron-left"
					customClass="prev-img"
					(click)="prev()"
				/>
				<app-button
					[variant]="buttonVariantEnum.Light"
					icon="mdi mdi-chevron-right"
					customClass="next-img"
					(click)="next()"
				/>
			</div>
		</ng-template>
		<ng-template pTemplate="thumbnail" let-item>
			<div class="thumbnail-img-container">
				<img [src]="item.thumbnailImageSrc" />
			</div>
		</ng-template>
	</p-galleria>

	<div class="rfid-btn-list gallery-buttons">
		<app-button [variant]="buttonVariantEnum.Light" tooltip="Download Selected Image" icon="mdi mdi-download" />
		<app-button [variant]="buttonVariantEnum.Primary" text="As Default" />
		<app-button [variant]="buttonVariantEnum.Light" tooltip="Delete Selected Image" icon="mdi mdi-delete" />
	</div>
</div>
