import { Injectable } from '@angular/core';
import { StoredHeadersService } from '@core/abstractions';
import { LocalStorageService } from '@shared/services';

@Injectable({
	providedIn: 'root',
})
export class PrintingProductStoredHeadersService extends StoredHeadersService {
	constructor(_localStorageService: LocalStorageService) {
		super('PrintingHeaderBinding', _localStorageService);
	}
}
