import { Injectable } from '@angular/core';
import { IPrinterSettingsValidation } from '@printing/models';

@Injectable({
	providedIn: 'root',
})
export class PrinterSettingsValidationService {
	constructor() {}

	public validateNotes(notes: string | undefined, errors: IPrinterSettingsValidation) {
		delete errors.notes;

		if (notes && notes.length > 1000) {
			errors.notes = 'Notes length must be less than 1000';
		}
	}
}
