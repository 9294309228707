@if (!overlay) {
	<div class="spinner-container">
		<img [width]="size" class="rotating-left" src="../../../../assets/images/spinner/spinner-lg.svg" alt="" />
		<img [width]="size" class="rotating-right" src="../../../../assets/images/spinner/spinner-sm.svg" alt="" />
	</div>
} @else {
	<div class="overlay">
		<div class="spinner-container">
			<img width="90" class="rotating-left" src="../../../../assets/images/spinner/spinner-sm.svg" alt="" />
			<img width="90" class="rotating-right" src="../../../../assets/images/spinner/spinner-lg.svg" alt="" />
		</div>
	</div>
}
