<div id="internal-layout-wrapper">
	<!-- left side menu -->
	<app-nav-menu></app-nav-menu>

	<div id="page-content-wrapper" class="layout-wrapper">
		<!-- Top navigation-->
		<div class="site-header-container">
			<!-- This functionality will be added in the next release  -->
			<!-- <a [routerLink]="['']" class="logo"> -->
			<a class="logo">
				<span><img src="assets/images/logo/logo_lg.svg" class="logo-lg" alt="logo-big" /></span>
				<span><img src="assets/images/logo/logo_sm.svg" class="logo-sm" alt="logo-small" /></span>
			</a>
			<div>
				@if (crumbs$ | async; as crumbs) {
					<p-breadcrumb [model]="crumbs"></p-breadcrumb>
				}
			</div>
			<div class="header-links">
				<i
					class="action-icon mdi mdi-printer-outline"
					pTooltip="Available Printers"
					position="right"
					tooltipPosition="bottom"
					(click)="showPrintersSidebar()"
				>
				</i>
				<a
					class="action-icon"
					pTooltip="Knowledge Base"
					tooltipPosition="bottom"
					href="https://support.simplerfid.com/en"
					target="_blank"
					><i class="mdi mdi-help-circle-outline static-icon"></i
				></a>
				<a
					class="action-link"
					pTooltip="Create a ticket"
					tooltipPosition="bottom"
					href="https://support.simplerfid.com/tickets-view/new"
					target="_blank"
					>Support</a
				>
			</div>
			@if (userResult$ | async; as userResult) {
				<div class="user-menu-wrapper">
					<div class="user-menu" (click)="menu.toggle($event)">
						<div class="user-avatar-container">
							<img [hidden]="!userResult.avatarUrl" [src]="userResult.avatarUrl" title="Edit Profile" />
							<i [hidden]="userResult.avatarUrl" class="mdi mdi-account-circle-outline"></i>
						</div>
						<div class="user-name-container">
							<span class="account-user-name">{{ userResult.userName }}</span>
							<span class="account-position">{{ userResult.role }}</span>
						</div>
					</div>
					<p-menu #menu [popup]="true" [model]="items">
						<ng-template pTemplate="item" let-item>
							@if (!item?.url) {
								<a class="p-menuitem-link" [routerLink]="item.routerLink">
									<span class="p-menuitem-text"> {{ item.label }}</span>
									<span *ngIf="item.badge" class="menu-item-badge-filled">{{ item.badge }}</span>
								</a>
							}
							@if (item?.url) {
								<a class="p-menuitem-link" [attr.href]="item.url">
									<span class="p-menuitem-text"> {{ item.label }}</span>
								</a>
							}
						</ng-template>
					</p-menu>
				</div>
			}
		</div>
		<!-- Page content-->
		<div class="page-content-container">
			<div class="app-content">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>

<printers-sidebar [(isVisible)]="isPrintersSidebarVisible"></printers-sidebar>
