export enum PrintProductProperty {
	sku = 'sku',
	quantity = 'quantity',
	lotNumber = 'lotNumber',
	expDate = 'expDate',
	cost = 'cost',
	bornDate = 'bornDate',
	serialNumber = 'serialNumber',
	sellBy = 'sellBy',
}
