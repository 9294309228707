import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonVariant } from '@shared/components';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
	public buttonVariantEnum = ButtonVariant;
	@Input() searchKeyword: string | null = '';
	@Output() searchKeywordChange = new EventEmitter<string>();

	constructor() {}

	emitValue() {
		if (this.searchKeyword != null) {
			this.searchKeywordChange.emit(this.searchKeyword);
		}
	}
}
