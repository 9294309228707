import { Pipe, PipeTransform } from '@angular/core';
import {
	CustomerPermissionViewModel,
	LocationPermissionViewModel,
	LocationTypePermissionViewModel,
} from '@core/models';

import { CustomerLightViewModel } from '@shared/models';

@Pipe({
	name: 'customerPermissionFilter',
	pure: false,
})
export class CustomerPermissionFilterPipe implements PipeTransform {
	transform(items: CustomerPermissionViewModel[], keyword: string): CustomerPermissionViewModel[] {
		if (!items || !keyword) {
			return items;
		}
		return items.filter(item => item.name.toLowerCase().includes(keyword.toLowerCase()));
	}
}

@Pipe({
	name: 'customerByKeywordFilter',
	pure: false,
})
export class CustomerFilterByKeywordPipe implements PipeTransform {
	transform(items: CustomerLightViewModel[], keyword: string): CustomerLightViewModel[] {
		if (!items || !keyword) {
			return items;
		}
		return items.filter(item => item.name.toLowerCase().includes(keyword.toLowerCase()));
	}
}

@Pipe({
	name: 'customerByIdsFilter',
	pure: false,
})
export class CustomerFilterByIdsPipe implements PipeTransform {
	transform(items: CustomerLightViewModel[], customerIds: number[]): CustomerLightViewModel[] {
		if (!items || !customerIds) {
			return items;
		}
		return items.filter(item => !customerIds.includes(item.id));
	}
}

@Pipe({
	name: 'locationFilter',
	pure: false,
})
export class LocationFilterPipe implements PipeTransform {
	transform(items: LocationPermissionViewModel[] | null, keyword: string): LocationPermissionViewModel[] | null {
		if (!items || !keyword) {
			return items;
		}
		return items.filter(_ => _.name.toLowerCase().includes(keyword.toLowerCase()));
	}
}

@Pipe({
	name: 'locationTypeFilter',
	pure: false,
})
export class LocationTypeFilterPipe implements PipeTransform {
	transform(
		items: LocationTypePermissionViewModel[] | null,
		keyword: string,
	): LocationTypePermissionViewModel[] | null {
		if (!items || !keyword) {
			return items;
		}

		return items.filter(
			_ => _.locations.filter(_ => _.name.toLowerCase().includes(keyword.toLowerCase())).length > 0,
		);
	}
}
