<div class="not-found-container">
	<div class="side-logo">
		<img src="assets/images/logo/logo_lg.svg" alt="Rfid Logo" />
	</div>
	<div class="side-description">
		<img src="assets/images/page-not-found.svg" alt="404 error" />
		<h2>Opps... Page not found.</h2>
		<p>Are you sure the website url IS CORRECT?</p>
		<a href=" ">Go Home</a>
	</div>
</div>
